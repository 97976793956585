import { BrandingData, DefaultBranding } from '@swe/data/branding-data';

export const LOCAL_STORAGE_BRANDING_KEY = 'amiraBranding';

/**
 * Formats a branding string.
 */
function formatBranding(branding: string): string {
  return branding.toLowerCase();
}

export function GetBrandKey(input: string): string {
  const branding = formatBranding(input);
  const brandKey = BrandingData[branding]?.brandKey
  return brandKey ? brandKey : DefaultBranding.brandKey;
}

export function GetBrandedAppName(input: string): string {
  const branding = formatBranding(input);
  const appName = BrandingData[branding]?.appName;
  return appName ? appName : DefaultBranding.appName;
}

export function GetBrandedCompanyName(input: string): string {
  const branding = formatBranding(input);
  const companyName = BrandingData[branding]?.companyName;
  return companyName ? companyName : DefaultBranding.companyName;
}

export function GetBrandedCopyright(input: string): string {
  const branding = formatBranding(input);
  const copyright = BrandingData[branding]?.copyright;
  return copyright ? copyright : DefaultBranding.copyright;
}

export function GetBrandedTutorName(input: string): string {
  const branding = formatBranding(input);
  const tutorName = BrandingData[branding]?.tutorName;
  return tutorName ? tutorName : DefaultBranding.tutorName;
}

export function GetBrandedTutorSkin(input: string): string {
  const branding = formatBranding(input);
  const tutorSkin = BrandingData[branding]?.tutorSkin;
  return tutorSkin ? tutorSkin : DefaultBranding.tutorSkin;
}

export function GetBrandedSubjectThumbnails(input: string): {[index: string]: {[index: number]: string}} {
  const branding = formatBranding(input);
  const subjectThumbnails = BrandingData[branding]?.subjectThumbnails;
  return subjectThumbnails ? subjectThumbnails : DefaultBranding.subjectThumbnails;
}

export function GetBrandedSubjectDescriptions(input: string): {[index: string]: {[index: number]: string}} {
  const branding = formatBranding(input);
  const subjectDescriptions = BrandingData[branding]?.subjectDescriptions;
  return subjectDescriptions ? subjectDescriptions : DefaultBranding.subjectDescriptions;
}

export function GetBrandedSubjectSubheadings(input: string): {[index: string]: {[index: number]: string}} {
  const branding = formatBranding(input);
  const subjectSubheadings = BrandingData[branding]?.subjectSubheadings;
  return subjectSubheadings ? subjectSubheadings : DefaultBranding.subjectSubheadings;
}

export function GetBrandedLogo(input: string): string {
  const branding = formatBranding(input);
  const logo = BrandingData[branding]?.logo;
  return logo ? logo : DefaultBranding.logo;
}

