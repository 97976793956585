import { inject, Injectable } from "@angular/core";
import Queries from "@swe/data/queries/amira-srs-api-queries";
import { MessageCodes } from "@swe/enums";
import { MessageService } from "@swe/features/message-popup/message.service";
import { AmiraSRSMutateActivityInput, AmiraSRSMutateActivityResult, AmiraSRSLicensingInput, AmiraSRSLicensingResult } from "@swe/types/amira-srs-api-types";
import { Apollo, ApolloBase } from "apollo-angular";

@Injectable({
    providedIn: 'root',
})
export class AmiraSRSService {
    public apollo: ApolloBase;
    
    private messageService: MessageService = inject(MessageService);
    
    constructor(private apolloProvider: Apollo) {
        this.apollo = apolloProvider.use('amiraSRS');
    }

    async createActivity(input: AmiraSRSMutateActivityInput): Promise<string | void> {
        return this.apollo.mutate<{ putActivity: AmiraSRSMutateActivityResult }>({
            mutation: Queries.CREATE_ACTIVITY_MUTATION,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            if (result.errors) {
                console.error('Error creating activity:', result.errors.map(e => e.message));
                throw new Error('Create activity query failed.');
            }

            if (!result.data?.putActivity?.activityId) {
                throw new Error('New Activity Id not found.');
            }

            return result.data.putActivity.activityId;
        }).catch((error) => {
            this.messageService.handleError(MessageCodes.HTTP_UNEXPECTED_NET_ERROR);
        });
    }

    async getLicensing(input: AmiraSRSLicensingInput): Promise<AmiraSRSLicensingResult | void> {
        return this.apollo.query<{ getAmiraLicenses: AmiraSRSLicensingResult }>({
            query: Queries.GET_LICENSING,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            if (result.errors) {
                console.error('Error getting licensing:', result.errors.map(e => e.message));
                throw new Error('Licensing query failed.');
            }

            const licensing = result.data?.getAmiraLicenses;
            if (!licensing) {
                throw new Error('Get licenses failed');
            }

            return licensing;
        }).catch((error) => {
            this.messageService.handleError(MessageCodes.HTTP_UNEXPECTED_NET_ERROR);
        });
    }
}