import { Injectable } from '@angular/core';
import { 
    KnowledgeBase,
    KnowledgeBaseModules, 
    KnowledgeBaseValue,
} from "../../types";
import { SupervisorDataTypes } from '../../enums';
import { 
    supervisorGetData,
    supervisorSetData,
    supervisorSaveKbData,
} from '@istation-hydra/runtime-wasm/JSWasmApi.js';
import {
    getLCMDateTime,
    isLCMDateTime,
    LCMDateTime,
} from '../lcm-data-common';

@Injectable()
export class KnowledgeBaseWASM implements KnowledgeBase{
	user_id?: string | number;
	readonly storageName = 'KnowledgeBase';
    kb_cache: KnowledgeBaseModules = {};

    supervisorGetDataRef = supervisorGetData;
    supervisorSetDataRef = supervisorSetData;
    supervisorSaveKbDataRef = supervisorSaveKbData;

    constructor(){}

    /**
     * Gets the knowledge base and returns it as a KnowledgeBaseModules object
     */
    async getAll(): Promise<KnowledgeBaseModules | null | undefined > {
        await this.updateKb();
        if(this.kb_cache){
            return this.kb_cache;
        }
        return null;
    }

    /**
     * Gets a key in the knowledge base 
     * @param section KB section of the key
     * @param key KB key to get
     */
    async get(section: string, key: string): Promise<KnowledgeBaseValue> {
        let returnValue = null;
        await this.updateKb();
        const modules = this.kb_cache;
        if(modules && modules[section] && modules[section][key]){
            const value: KnowledgeBaseValue = modules[section][key];
            returnValue = isLCMDateTime(value) ? new Date((value as LCMDateTime).variantValue * 1000) : value;
        }
        return returnValue;
    }

    /**
     * Sets a key in the knowledge base
     * @param section KB section of the key
     * @param key KB key to set
     * @param value KB value to set the key to
     */
    set(section: string, key: string, value: KnowledgeBaseValue): void {
        const setValue = value instanceof Date ? getLCMDateTime(value, this.kb_cache[section][key]) : value;

        if(this.kb_cache[section]){
            this.kb_cache[section][key] = setValue;
        }
        else {
            Object.defineProperty(this.kb_cache, section, {value: {[key]: setValue}, writable: true, enumerable: true} );
        }
    }

    /**
     * Forces the kb to sync with the server
     */
    async sync(): Promise<void> {
        const jsonStr = JSON.stringify(this.kb_cache);  
        const error = await this.supervisorSetDataRef(this.user_id, SupervisorDataTypes.KB, jsonStr);
        if(error){
            console.error('SupervisorSetData error: ', error);
        } 
        else{
            this.supervisorSaveKbDataRef(this.user_id);
        }
    }

    /**
     * Sets a key in the knowledge base then force syncs to the server.
     * @param section KB section of the key
     * @param key KB key to set
     * @param value KB value to set the key to
     */
    async setAndSync(section: string, key: string, value: KnowledgeBaseValue): Promise<void>{
        await this.updateKb();
        this.set(section, key, value);
        if(window._isapp_module){
            await this.sync();
        }
    }

    /**
     * get the KB using LCM
     */
    private async retrieve(): Promise<KnowledgeBaseModules> {
        if(this.user_id){
            const rawString = await this.supervisorGetDataRef(this.user_id, SupervisorDataTypes.KB);
            return JSON.parse(rawString) as KnowledgeBaseModules;
        }
        return {};
    }

    /**
     * Sets the kb to kb_cache for editing
     */
    private async updateKb(){
        await this.retrieve().then((value) => {
            this.kb_cache = value;
        })
    }
}