import { inject, Injectable, Injector, ProviderToken } from '@angular/core';
import {
  ConfigDataAPI,
  ConfigDataWASM,
} from '@swe/classes/config-data'
import {
  ConfigDataObject,
  ConfigDataValue,
} from '@swe/types';
import {
  ISIPToggleSubject,
  ToggleStatus,
} from '@swe/enums';

const getConfigDataFacade = (method?: 'api' | 'runtime' | 'emscripten' | string) => {
    method = 'runtime';
    const injectionPoint: ProviderToken<ConfigDataWASM | ConfigDataAPI> = method === 'runtime' 
        ? ConfigDataWASM 
            : method === 'emscripten'
                ? ConfigDataAPI 
                    : ConfigDataAPI
  const facade = inject(injectionPoint);
  return facade;
}

@Injectable()
export class ConfigDataService {
  private _configData = getConfigDataFacade();
  get userOid(): number {
    return this._configData.userOid;
  }
  set userOid(oid: number) {
    this._configData.userOid = oid;
  }

  constructor(private injector: Injector){}

  async loadData(): Promise<string | null> {
    return await this._configData.loadData();
  }

  async get(section: string, key: string = ''): Promise<ConfigDataValue> {
    return this._configData.get(section, key);
  }

  clearData(): void {
    this._configData.clearData();
  }

  // #region Check specific ConfigData values
  public async isAtSchool(): Promise<boolean> {
    // an authorized IP is considered AT SCHOOL, not at home
    return await this.get('Features', 'AuthorizedIp').then(value => !!value);
  }

  public async isGamesVisible(subject: string): Promise<boolean> {
    const key = "GAMES_SCHOOL_VISIBILITY_SETTING";
    const section = ISIPToggleSubject[subject.toUpperCase() as keyof typeof ISIPToggleSubject];
    const visible = await this.get('ISIPToggle', section).then(
      v => !!v && (v as ConfigDataObject)[key] === ToggleStatus.Visible
    );

    return visible ? visible : !await this.isAtSchool(); // if not visible everywhere, then check if not within authorized IP range (aka school)
  }

  public async isIpracticeVisible(subject: string): Promise<boolean> {
    const key = "IPRACTICE_SCHOOL_VISIBILITY_SETTING";
    const section = ISIPToggleSubject[subject.toUpperCase() as keyof typeof ISIPToggleSubject];
    const visible = await this.get('ISIPToggle', section).then(
      v => !!v && (v as ConfigDataObject)[key] === ToggleStatus.Visible
    );

    return visible ? visible : !await this.isAtSchool(); // if not visible everywhere, then check if not within authorized IP range (aka school)
  }
  //#endregion
}