/**
 * Feature flags that we use should be listed here rather than typed elsewhere.
 * @enum {string}
 */
 export enum FeatureFlagsEnum {
    //This is for devs to see various debug utilities throughout OLP.
    DEBUG_TOOLS = 'debugTools',
    HAS_SETTINGS = 'hasSettings',
    EARLY_ACCESS = 'earlyAccess',
    ENABLE_AMIRA_INBOX = 'enableAmiraInbox',
}