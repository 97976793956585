import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AnswerStateEnum } from '../../enums';

@Component({
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
  selector: 'swe-preview-progress-bar',
  templateUrl: './preview-progress-bar.component.html',
  styleUrls: ['./preview-progress-bar.component.scss'],
})
export class PreviewProgressBarComponent {
  @Input() numQuestions = 0;
  @Input() hasVideo = false;
  @Input()
  set progressDetails(values: Array<AnswerStateEnum>) {
    const steps = [...values];
    const classNames = ['partial', 'correct', 'incorrect', 'complete'];
    steps.length = this.numQuestions;
    this.nodeStates = steps
      ?.map((state) =>
        typeof state === 'undefined' || state < 0
          ? 'unanswered'
          : classNames[state] || ''
      )
      .fill('unanswered', values.length);
  }
  public nodeStates: Array<string> = [];
}
