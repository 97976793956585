import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  User,
  StudentSubscription,
  StudentSubject,
  StudentSubjectIsip,
  KnowledgeBaseValue,
} from '../types';
import { FeatureFlagsEnum, GradeBands, KnowledgeBaseKeys, Themes } from '../enums';
import { GRADE_BANDS } from '../data/grade-bands';
import { THEMES } from '../data/themes';
import { ConfigDataService, FeatureFlagService, KnowledgeBaseService } from './';
import subjectGroups from '../data/subject-groups';
import { getIsipName, SubjectCard, SubjectFactory } from '../classes';
import { filter } from 'rxjs/operators';
import { getNumStars } from './../shared/utilities/user';
import { LegacyAssignment, LegacyAssignmentActivity } from '../types';
import { isLegacyAssignmentComplete } from '../shared/utilities/legacy-activity-utils';
import { Title } from '@angular/platform-browser';
import { GetBrandedAppName, LOCAL_STORAGE_BRANDING_KEY } from '@swe/shared/utilities/branding-utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** Main user object provided through the service */
  user = new BehaviorSubject<User | undefined>(undefined);

  theme = new BehaviorSubject<string>(Themes.DEFAULT);
  overlayEnabled = new BehaviorSubject<boolean>(false);
  givenName = new BehaviorSubject<string>('');
  familyName = new BehaviorSubject<string>('');
  fullName = new BehaviorSubject<string>('');
  domain = new Subject<string>();
  gradeBand = new BehaviorSubject<number>(GradeBands.NONE);
  avatar = new BehaviorSubject<number>(-1);
  storedAvatar = new BehaviorSubject<number>(-1);
  stars = new BehaviorSubject<number>(0);
  userSubscription!: StudentSubscription;
  userIsips = new BehaviorSubject<StudentSubjectIsip[]>([]);
  userSubjects = new BehaviorSubject<StudentSubject[]>([]);
  userVerbals = new BehaviorSubject<SubjectCard[]>([]);
  currentSubjects = new BehaviorSubject<SubjectCard[]>([]);
  amiraSchoolId = new BehaviorSubject<string>('');
  amiraDistrictId = new BehaviorSubject<string>('');
  amiraBranding = new BehaviorSubject<string>(localStorage.getItem(LOCAL_STORAGE_BRANDING_KEY) ?? 'amira');

  roles: Array<string> = [];

  needsIntro = false;
  isTeacher = false;
  isDebugUser = false;
  isLoaded = new BehaviorSubject<boolean>(false);

  getBand = (grade: number): number =>
    GRADE_BANDS.find(
      ({ availableGrades }) => availableGrades.indexOf(grade) >= 0
    )?.id || GradeBands.NONE;

  constructor(
    private kbService: KnowledgeBaseService,
    private configDataService: ConfigDataService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private titleService: Title,
  ) {
    this.restoreUserFromLocalStorage();
    this.user.subscribe(async (user) => {
      if (user) {
        // Sets kbService and configService userOids
        this.kbService.userID = user && user.id ? user.id.toString() : '';
        this.configDataService.userOid = user && user.id ? user.id : 0;

        if (user.givenName && user.givenName !== this.givenName.getValue()) {
          this.givenName.next(user.givenName);
        }
        if (user.familyName && user.familyName !== this.familyName.getValue()) {
          this.familyName.next(user.familyName);
        }
        if (user.fullName && user.fullName !== this.fullName.getValue()) {
          this.fullName.next(user.fullName);
        }
        if (typeof user.grade !== 'undefined') {
          this.gradeBand.next(this.getBand(user.grade));
        }
        if (user.avatar) {
          this.avatar.next(user.avatar);
          this.setUserKbAvatar(user.avatar);
        }
        if (user.stars) {
          this.stars.next(user.stars);
          this.setUserKbStars(user.stars);
        }
        if (user.amiraSchoolId && user.amiraSchoolId !== this.amiraSchoolId.getValue()) {
          this.amiraSchoolId.next(user.amiraSchoolId);
        }
        if (user.amiraDistrictId && user.amiraDistrictId !== this.amiraDistrictId.getValue()) {
          this.amiraDistrictId.next(user.amiraDistrictId);
        }
        if (user.amiraBranding && user.amiraBranding !== this.amiraBranding.getValue()) {
          this.amiraBranding.next(user.amiraBranding);
        }
        this.isTeacher = user.isTeacher;
        window.localStorage.setItem('user', JSON.stringify(user));
      }
    });
    this.gradeBand
      .pipe(
        //ignore the initial gradeband set to none so we're not changing theme to default.
        filter((bandId) => bandId !== GradeBands.NONE)
      )
      .subscribe((bandId) => {
        const branding = this.featureFlagService.isFlagEnabled(FeatureFlagsEnum.ENABLE_AMIRA_INBOX) ? this.amiraBranding.value : undefined;

        // update subject descriptions
        if (this.userSubscription?.isips) {
          SubjectFactory.updateAllSubjectsDetails(
            this.userSubscription.isips,
            bandId,
            branding
          );
          this.userIsips.next(this.userSubscription.isips);
        }
        this.currentSubjects.next(
          SubjectFactory.updateSubjectCards(
            this.currentSubjects.getValue(),
            bandId,
            branding
          )
        );
        this.userVerbals.next(
          SubjectFactory.updateSubjectCards(this.userVerbals.getValue(), bandId, branding)
        );

        // assess whether the current theme is valid for this grade band
        if (!this.isActiveThemeAvailableForUser()) {
          this.theme.next(this.defaultTheme);
        }
      });
    this.theme.subscribe((themeName) => {
      if (themeName) localStorage.setItem('theme', themeName);
    });
    this.stars.subscribe((starCount) => this.setUserKbStars(starCount));
    this.overlayEnabled.subscribe((overlayStatus) =>
      localStorage.setItem('overlay', String(overlayStatus))
    );

    this.amiraBranding.subscribe((branding) => {
      if (this.featureFlagService.isFlagEnabled(FeatureFlagsEnum.ENABLE_AMIRA_INBOX)) {
        localStorage.setItem('amiraBranding', branding);
        this.titleService.setTitle(`Welcome to ${GetBrandedAppName(branding)}!`);
      }
    });
  }

  restoreUserFromLocalStorage(): void {
    const theme = localStorage.getItem('theme');
    const overlayStatus = localStorage.getItem('overlay');
    if (theme) {
      this.theme.next(theme);
    }
    if (overlayStatus) {
      this.overlayEnabled.next('true' === overlayStatus);
    }
  }

  get learningPaths(): StudentSubject[] | undefined {
    return this.userSubscription.subjects;
  }

  get isips(): StudentSubjectIsip[] | undefined {
    return this.userSubscription.isips;
  }

  get grade(): number {
    return this.user.value?.grade || 0;
  }

  get id(): number {
    return this.user.value?.id || 0;
  }

  get hasUsageMeter(): boolean {
    return this.featureFlagService.isFlagEnabled('usageMeterEarlyAccess') &&
      this.hasRole('usageMeterPreview') &&
      !this.hasRole('teacher') &&
      this.gradeBand.value != GradeBands.BAND_1;
  }

  async updateIsipDataFromKb(name: string): Promise<void> {
    this.userSubscription.isips = await Promise.all(
      this.userSubscription.isips.map(async (isip) => {
        if (isip.name.toLowerCase() === name.toLowerCase()) {
          const lastCompletedDate = await this.getKbLastCompletedIsip(
            isip.kbIsipFolder
          );
          return { ...isip, lastCompleted: lastCompletedDate };
        }
        return isip;
      })
    );

    this.userIsips.next(this.userSubscription.isips);
  }

  async getKbLastCompletedIsip(
    kbIsipFolder: string
  ): Promise<Date | undefined> {
    return this.kbService.get(kbIsipFolder, 'lastCompleted').then((value) => {
      let returnValue: Date | undefined = undefined;
      if (value) {
        if (Object.prototype.toString.call(value) === '[object Date]') {
          returnValue = value as Date;
        } else if (Number.isInteger(value)) {
          returnValue = new Date((value as number) * 1000);
        }
      }
      // console.log(`%c getKbLastCompletedIsip => value[${value}] type[${typeof value}] isDate[${value && Object.prototype.toString.call(value) === '[object Date]'}] isNumber[${value && Number.isInteger(value)}] returnValue[${returnValue}]`, 'background:cyan;color:black;')
      return returnValue;
    });
  }

  determineIntroToComputerSubjects(): string[] {
    const introSubjects = [
      ...subjectGroups.ReadingSubjects,
      ...subjectGroups.SpanishSubjects,
      ...subjectGroups.MathSubjects,
    ];
    return this.userSubjects.value.reduce<Array<string>>((acc, x) => {
      if (introSubjects.includes(x.name)) acc.push(x.name);
      return acc;
    }, []);
  }

  get defaultTheme(): string {
    const defaultTheme = THEMES[0];
    return (
      THEMES.find(
        ({ availableGradeBands, isDefault }) =>
          isDefault && availableGradeBands.indexOf(this.gradeBand.value) >= 0
      ) || defaultTheme
    ).short_name;
  }

  isActiveThemeAvailableForUser(): boolean {
    return THEMES.some(
      ({ short_name, availableGradeBands }) =>
        short_name === this.theme.value &&
        availableGradeBands.indexOf(this.gradeBand.value) >= 0
    );
  }

  /**
   * Determine if the student needs intro to computers
   * @returns true if the student needs intro to computers, false if they don't
   */
  async getNeedsIntro(): Promise<boolean> {
    const [hasSeenTs, hasSeenPc] = await Promise.all([
      this.kbService.get(
        KnowledgeBaseKeys.IntroToComputers,
        KnowledgeBaseKeys.HasSeenTS
      ),
      this.kbService.get(
        KnowledgeBaseKeys.IntroToComputers,
        KnowledgeBaseKeys.HasSeenPC
      ),
    ]);
    this.needsIntro = !hasSeenTs && !hasSeenPc;
    return this.needsIntro && this.gradeBand.value === GradeBands.BAND_1;
  }

  /**
   * Gets the user's selected avatar from the knowledge base
   * @returns Promise<any> Avatar index stored in the knowledge base
   */
  getUserKbAvatar(): Promise<KnowledgeBaseValue> {
    return this.kbService
      .get(
        'PowerPath_ActivityCustomizations',
        'PPM_AVATARS_CustomizationSelected'
      )
      .then((v) => {
        return v;
      });
  }

  /**
   * Sets the user avatar in the knowledge base
   * @param avatarChoice the avatar index the user has chosen
   * @return Promise<void>
   */
  setUserKbAvatar(avatarChoice: number): Promise<void> {
    return this.kbService.setAndSync(
      'PowerPath_ActivityCustomizations',
      'PPM_AVATARS_CustomizationSelected',
      avatarChoice
    );
  }

  getUserConfigGrade(): Promise<string | undefined> {
    return this.configDataService.get('Supervisor', 'Grade').then((v) => {
      return v?.toString();
    });
  }

  /**
   * Gets the number of stars the user currently has
   * @returns the number of stars the user currently has
   */
  getUserKbStars(): Promise<number> {
    return this.kbService.get('MainMenu', 'Stars')
      .then((v) => v ? parseInt(v as string, 10) : 0);
  }

  /**
   * Sets the user avatar in the knowledge base
   * @param avatarChoice the avatar index the user has chosen
   * @return Promise<void>
   */
  setUserKbStars(stars: number): Promise<void> {
    return this.kbService.setAndSync('MainMenu', 'Stars', stars);
  }

  /**
   * Adds stars for the user
   * @param {number} numStars number of stars to add for the user
   */
  addUserStars(numStars: number): void {
    if (numStars < 0) throw new Error('Number of stars less than 0');
		const currentStars = this.stars.value + numStars;
    this.stars.next(currentStars);	// will trigger call to "setUserKbStars"
  }

  /**
   * Returns the number of stars earned from the last test of the given subject.
   * If there isn't any last test data or data is invalid, then "0" is returned.
   * @param subjectName the subject name as string
   * @returns 
   */
  async getLatestTestStars(subjectName: string): Promise<number> {
    const grade = this.user.value?.grade || 0;
    const kbFolder = getIsipName(subjectName, grade);
    if (!kbFolder) {
      return Promise.resolve(0);
    }

    const subjectArray = this.currentSubjects.getValue();
    const subjectInfo = subjectArray.find(subj => subj.name.toLowerCase() === subjectName.toLowerCase());
    if (!subjectInfo) {
      return Promise.resolve(0);
    }

    const monthInfoArray = subjectInfo.isip.totalScores;
    const monthInfo = monthInfoArray[monthInfoArray.length - 1]; // monthInfoArray.pop();
    if (!monthInfo) {
      return Promise.resolve(0);
    }

    const { period: lastPeriod, value: scoreValue } = monthInfo;
    const goal = subjectInfo.isip.goals.find(({period: subjectGoalPeriod}) => subjectGoalPeriod === lastPeriod);
    const goalValue = goal ? Math.round(goal.value) : -1;
    const numStars = getNumStars(scoreValue, goalValue, subjectInfo.isip.totalScores.length === 1);
    return Promise.resolve(numStars);
  }

  hasRole(role: string): boolean {
    return this.roles.includes(role);
  }

  async hasAssignments(subject: string | undefined = undefined): Promise<boolean> {
    const response = await this.configDataService.get('Assignments');
    if (typeof response !== 'string') {
      return Promise.resolve(false);
    }
    const r = Object
      .values(JSON.parse(response as string))
      .some((assignment) => {
        return !isLegacyAssignmentComplete(assignment as LegacyAssignment, subject);
      });
    return Promise.resolve(r);
  }
}
