import { Branding } from "@swe/types/branding-types"
import SubjectDescriptions, { NWEASubjectDescriptions } from "./subject-descriptions"
import SubjectSubheadings, { NWEASubjectSubheadings } from "./subject-subheadings"
import SubjectThumbnails, { NWEASubjectThumbnails } from "./subject-thumbnails"

export const DefaultBranding = { 
  brandKey: 'amira',
  appName: 'Amira',
  companyName: 'Amira Learning',
  logo: 'assets/logos/Amira-Logo.svg',
  copyright: `Copyright © 2018-${new Date().getFullYear()} Amira Learning, Inc. All rights reserved.`,
  tutorName: 'Amira',
  tutorSkin: 'Amira',
  subjectThumbnails: SubjectThumbnails,
  subjectDescriptions: SubjectDescriptions,
  subjectSubheadings: SubjectSubheadings,
}

export const BrandingData: Record<string, Branding> = {
  amira: DefaultBranding,
  eps: { 
    brandKey: 'eps',
    appName: 'Reading Assistant',
    logo: 'assets/logos/EPS-Logo.png',
    companyName: 'EPS',
    copyright: '© EPS Operations, LLC. All rights reserved.',
  },
  ["eps-standalone-reading-assistant"]: { 
    brandKey: 'eps-standalone-reading-assistant',
    appName: 'Reading Assistant',
    logo: 'assets/logos/EPS-Standalone-Logo.svg',
    companyName: 'EPS',
    copyright: '© EPS Operations, LLC. All rights reserved.',
  },
  highlights: { 
    brandKey: 'highlights',
    logo: 'assets/logos/Highlights-Logo.svg',
    copyright: 'Copyright © Highlights for Children, Inc., Columbus Ohio. All rights reserved.',
  },
  hmh: {
    brandKey: 'hmh',
    copyright: 'Copyright © by Houghton Mifflin Harcourt Publishing Company. All rights reserved.',
  }, 
  ['nwea-map-tutor']: {
    brandKey: 'nwea-map-tutor',
    appName: 'Map Reading Fluency',
    logo: 'assets/logos/NWEA-Logo.svg',
    companyName: 'NWEA',
    copyright: `© NWEA ${new Date().getFullYear()}. MAP is a registered trademark. NWEA, MAP Growth, MAP Skills, and MAP Reading Fluency are trademarks of NWEA in the U.S. and in other countries. This system contains confidential information that may be subject to applicable federal and state law. By accessing this system, you shall comply with such applicable laws, the terms and conditions of your agreement with NWEA, and your entity’s policies regarding the use, transmission and handling of such confidential information.`,
    tutorName: 'Maya',
    tutorSkin: 'Maya',
    subjectThumbnails: NWEASubjectThumbnails,
    subjectDescriptions: NWEASubjectDescriptions,
    subjectSubheadings: NWEASubjectSubheadings
  },
}