import { gql } from "apollo-angular";

// schema definition: https://github.dev/AmiraLearning/amira-assignment-service/blob/develop/res/schema.graphql

export const ALL_ASSIGNMENT_QUERY = gql`
    query ($studentId: ID!, $schoolId: ID!, $districtId: ID!) {
        getAllAssignments (studentId: $studentId, schoolId: $schoolId, districtId: $districtId) {
            assignmentId
            assignmentType
            createdAt
            dateFrom
            dateTo
            entityId
            entityType
            grade
            status
            activity {
                activityId
                createdAt
                displayStatus
                schoolId
                updatedAt
                type
                studentId
                storyId
                status
                score_pass
                assignmentId
                parentId
            }
            manifest {
                activityType
                contentTags
                locale
                sequence
                status
                storyId
                launchArgs {
                    skill
                    activityOid
                    activityUuid
                }
            }
        }
    }
`;

export const ACTIVE_ASSIGNMENT_QUERY = gql`
    query ($studentId: ID!, $schoolId: ID!, $districtId: ID!) {
        studentActiveAssignments (studentId: $studentId, schoolId: $schoolId, districtId: $districtId) {
            assignmentId
            assignmentType
            createdAt
            dateFrom
            dateTo
            entityId
            entityType
            grade
            status
            manifest {
                activityType
                contentTags
                locale
                sequence
                status
                storyId
                launchArgs {
                    skill
                    activityOid
                    activityUuid
                }
            }
        }
    }
`;

export const NEXT_ASSIGNMENT_QUERY = gql`
    query ($studentId: ID!, $schoolId: ID!, $districtId: ID!) {
        studentInbox (studentId: $studentId, schoolId: $schoolId, districtId: $districtId) {
            assignmentId
            assignmentType
            createdAt
            dateFrom
            dateTo
            entityId
            entityType
            grade
            status
            manifest {
                activityType
                contentTags
                locale
                sequence
                status
                storyId
                launchArgs {
                    skill
                    activityOid
                    activityUuid
                }
            }
        }
    }
`;

export default { 
    ALL_ASSIGNMENT_QUERY, 
    ACTIVE_ASSIGNMENT_QUERY, 
    NEXT_ASSIGNMENT_QUERY 
};