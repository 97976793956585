/**
 * Message states in application
 * @enum {number}
 */
export enum MessageCodes {
    HTTP_AUTH_REQUIRED      = 401,     // Authentication Required
    HTTP_FORBIDDEN          = 403,     // Not Authorized
    HTTP_NOT_FOUND         = 404,     // Page Not Found
    HTTP_UNEXPECTED_NET_ERROR = 409,   // Unexpected Network Error
    HTTP_NET_GENERIC       = 499,     // Unrecognized Network Error
    HTTP_SERVER_INTERNAL   = 500,     // Internal Server Error
    HTTP_SERVICE_UNAVAIL   = 503,     // Service Unavailable
    APP_CONTENT_LOAD       = 900,     // Error loading APP content
    APP_STORAGE            = 901,     // App Storage Warning
    APP_AUDIO_DEV          = 902,     // App Audio Device Failure
    APP_NETWORK            = 903,     // Network Error
    APP_RES                = 904,     // Resolution Warning
    APP_WARN_REFRESH       = 905,     // Refresh Warning
    APP_MULTI_INSTANCE     = 906,     // Multiple Instances Running Warning
    APP_COMPAT             = 907,     // Compatibility Message
    APP_FATAL_SEQUENCE     = 908,     // Fatal Error (App Sequence Error)
    APP_OFFLINE            = 909,     // Running Offline
    APP_LOGOUT_CONFIRM     = 1001,    // Confirm Logout
    APP_CLOSE_CONFIRM      = 1002,    // Confirm Closing Tab/Window While Logged In
    APP_IDLE               = 1003,    // Idle Message
    APP_NAV_ORF            = 1004,    // ORF Confirmation
    APP_NAV_RAN            = 1005,    // RAN Confirmation
    APP_NAV_ORF_ES         = 1006,    // Spanish ORF Confirmation
    APP_NAV_RAN_ES         = 1007,    // Spanish RAN Confirmation
    RUNTIME_LOGIN_ERROR    = 1105,    // Runtime Login Error
    NO_CLASS               = 1110,    // No Class Assigned
    ACTIVITY_BROWSER_NAV   = 1115     // Browser Navigation in Activity
}