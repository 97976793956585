export enum AmiraAssignmentActivityTypes {
    ASSESSMENT = 'assessment',
    MICROLESSON = 'microLesson',
    LEGACYISTATION = 'istationLegacyActivity',
    STORY = 'story',
    SKILL = 'skill',
    UNKNOWN = "unknown",
}

export enum AmiraAssignmentStatus {
    ASSIGNED = 'ASSIGNED',
    COMPLETED = 'COMPLETED',
    INPROGRESS = 'INPROGRESS',
    SCORED = 'SCORED',
}

export enum AmiraAssignmentEntityTypes {
    DISTRICT = 'DISTRICT',
    SCHOOL = 'SCHOOL',
    GROUP = 'GROUP',
    TEACHER = 'TEACHER',
    STUDENT = 'STUDENT',
}