import { AmiraAssignmentResponse, Manifest } from "@swe/types";
import { AmiraAssignmentDisplayStatus, AmiraAssignmentActivityTypes, AmiraAssignmentTypes } from "@swe/enums";

/**
 * Determines if a manifest is incomplete by checking:
 * 1. If it has no activities array or empty activities array
 * 2. If none of its activities have a displayStatus of "completed"
 * @param manifestActivity The manifest object to check
 * @returns true if the manifest is incomplete, false otherwise
 */
export function isManifestActivityIncomplete(manifestActivity: Manifest): boolean {
    // If manifest has no activities array or it's empty, it's incomplete
    if (!manifestActivity.activity || manifestActivity.activity.length === 0) {
        return true;
    }

    // If there are no activities with displayStatus of "completed", it's incomplete 
    return manifestActivity.activity.every(activity => activity.displayStatus !== AmiraAssignmentDisplayStatus.COMPLETED);
}

/**
 * Filters out manifest activities with invalid or UNKNOWN activity types
 * @param manifest Array of manifest activities to filter
 * @returns Filtered array of manifest activities
 */
export function filterInvalidManifestActivities(manifest: Manifest[]): Manifest[] {
    return manifest.filter(activity =>
        Object.values(AmiraAssignmentActivityTypes).includes(activity.activityType) &&
        activity.activityType !== AmiraAssignmentActivityTypes.UNKNOWN
    );
}

/**
 * Finds the first assignment that has any incomplete manifest activities
 * @param assignments Array of assignments to check
 * @returns First assignment with incomplete activities or undefined if none found
 */
export function findNextIncompleteAssignment(assignments: AmiraAssignmentResponse[]): AmiraAssignmentResponse | undefined {
    return assignments.find(assignment => 
      assignment.manifest &&
      assignment.manifest.some(manifestActivity => isManifestActivityIncomplete(manifestActivity))
    );
}

/**
 * Checks if the assignment type is an assessment-related type
 * @param assignmentType The type to check
 * @returns true if the type is ASSESSMENT, BENCHMARK, CALIBRATION or PROGRESS_MONITORING
 */
export function isAssessmentTypeAssignment(assignmentType: AmiraAssignmentTypes): boolean {
    return [
        AmiraAssignmentTypes.ASSESSMENT,
        AmiraAssignmentTypes.BENCHMARK,
        AmiraAssignmentTypes.CALIBRATION,
        AmiraAssignmentTypes.PROGRESS_MONITORING
    ].includes(assignmentType);
}

