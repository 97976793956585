import { MessageService } from '../features/message-popup/message.service';
import { MessageCodes } from '@swe/enums';

export type HttpParams = { [index: string]: string | number };

export type HttpHeaders = { [key: string]: string };

export type HttpMethods = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';

export interface RequestOptions {
  params?: HttpParams;
  headers?: HttpHeaders;
}

export interface RequestOptionsWithoutData extends RequestOptions {
  ccoVersion?: number;
  crc?: number;
  modules?: Record<string, unknown>;
  removeSet?: string[] | null;
  userId?: number;
}

export interface RequestOptionsWithData<T extends Record<string, unknown>> extends RequestOptions {
  data?: T;
}

export interface FetchConfig {
    baseUrl?: string;
    defaultHeaders?: HttpHeaders;
    csrfCookieName?: string;
    csrfHeaderName?: string;
    trailingSlash?: TrailingSlashOptions;
}

export type TrailingSlashOptions = 'always' | 'none' | 'write_only'

/**
 * Library loosely adapted from the premise in ngx-fetch-api
 */
export class APIHandler {
    baseUrl = 'https://api-priv.hydra.dev.istation.net/olpdata-priv-6nyf9en0z7';
    endpoint?: string;
    failSilently = false;
    private defaultHeaders: HttpHeaders = {};
    private trailingSlash: TrailingSlashOptions = 'write_only';
    private _messageService!: MessageService;

    constructor(config?: FetchConfig, private configId = 'idsrv-dev') {
        if (config) {
            this.configure(config);
        }
    }

    set messageService(messageService: MessageService) {
        this._messageService = messageService;
    }

    configure(config: FetchConfig): void {
        if (config.baseUrl) {
          this.baseUrl = config.baseUrl.endsWith('/')
            ? config.baseUrl.slice(0, -1)
            : config.baseUrl;
        }
    
        if (config.defaultHeaders) {
          this.defaultHeaders = config.defaultHeaders;
        }
    
        if (config.trailingSlash) {
          this.trailingSlash = config.trailingSlash;
        }
    }

    getHeaders(headers?: HttpHeaders): HttpHeaders {
        const newHeaders = {
          ...this.defaultHeaders,
          ...headers,
        };
        
        const authTokenSessionStorage = this.getAuthTokenFromSessionStorage();
        if (authTokenSessionStorage) {
          newHeaders.Authorization = `Bearer ${authTokenSessionStorage}`;
        }
        
        return newHeaders;
    }
    
    getAuthTokenFromSessionStorage(): string {
        const idsrvSess = sessionStorage.getItem(this.configId);
        if (idsrvSess) {
          const sessJSON = JSON.parse(idsrvSess);
          const authToken = sessJSON.authnResult.access_token;
          if (authToken) {
            return authToken;
          }
        }

        return '';
    }
    
    async request<TResponse extends {} | void>(
        method: HttpMethods,
        path: string,
        retries: number,
        options?:
            | RequestOptionsWithoutData
            | RequestOptionsWithData<Partial<TResponse>>
            | RequestOptions
    ): Promise<TResponse> {
        const fetchOptions: { [key: string]: unknown } = { method };
        const headers: { [key: string]: unknown } =
            this.getHeaders(options?.headers) || {};
        const url: string = ((): string => {
            let url = path.startsWith('/') ? path : `${this.baseUrl}/${path}`;
            if (!path.endsWith('/')) {
                const ts = this.trailingSlash;
                if (ts === 'always' || (ts === 'write_only' && method !== 'GET')) {
                    path = path + '/';
                }
            }
            if (options && options.params && Object.keys(options.params).length > 0) {
                url +=
                    '?' +
                    Object.entries(options.params)
                        .reduce((acc, [k, v]) => {
                            k &&
                            v !== null &&
                            typeof v !== 'undefined' &&
                            acc.push(
                                `${encodeURIComponent(k)}=${encodeURIComponent('' + v)}`
                            );
                            return acc;
                        }, [] as string[])
                        .join('&');
            }
            return url;
        })();

        if (method !== 'GET' && (<RequestOptionsWithData<Partial<TResponse>>>options)?.data) {
            fetchOptions['body'] = JSON.stringify(
                (<RequestOptionsWithData<Partial<TResponse>>>options).data
            );
        }
    
        fetchOptions['headers'] = headers;
        
        return fetch(url, fetchOptions)
            .then(async response => {
                const contentType = response.headers.get('Content-Type');
                const isJson = contentType?.includes('application/json');
                if (this.hasValidResponse(response)) {
                    return isJson ? (await response.json() as TResponse) : null;
                } else if (response.status === 400 && isJson) {
                    const json = await response.json();
                    throw json;
                } else if (response.status >= 500 && response.status !== 504) {
                    if (retries > 32) {
                        throw response;
                    }
                    
                    return new Promise(resolve => {
                        setTimeout(async () => {
                            const res = await this.request(method, path, retries * 2, options);
                            resolve(res);
                        }, retries * 1000);
                    });
                }
                throw response;
            })
            .then(json => {
                if (this.hasApiMessage(json)) {
                    throw (json as { message: string }).message;
                } else {
                    return json as TResponse;
                }
            });
    }

    async get<TResponse extends {}>(path: string, options?: RequestOptions): Promise<TResponse> {
        return await this.request<TResponse>('GET', path, 2, options);
    }
    
    post<TResponse extends {}>(path: string, options?: RequestOptionsWithoutData | RequestOptionsWithData<TResponse>): Promise<TResponse> {
        return this.request<TResponse>('POST', path, 2, options);
    }

    put<TResponse extends {}>(path: string, options?: RequestOptionsWithoutData | RequestOptionsWithData<TResponse>): Promise<TResponse> {
        return this.request<TResponse>('PUT', path, 2, options);
    }

    patch<TResponse extends {}>(path: string, options?: RequestOptionsWithoutData | RequestOptionsWithData<Partial<TResponse>>): Promise<TResponse> {
        return this.request<TResponse>('PATCH', path, 2, options);
    }

    delete(path: string, options?: RequestOptions): Promise<void> {
        return this.request<void>('DELETE', path, 2, options);
    }

    private hasValidResponse(apiResponse: Response): boolean {
        return apiResponse && apiResponse.ok && apiResponse.status >= 200 && apiResponse.status < 300 && !!apiResponse.body;
    }

    private hasApiMessage<T>(apiResponse: T | null): boolean {
        return !!apiResponse && Object.prototype.hasOwnProperty.call(apiResponse, 'message')
    }
}