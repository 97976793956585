import { Router } from '@angular/router';
import {
  HostListener,
  Injectable,
  OnDestroy,
} from '@angular/core';
import {
  HttpClient,
  HttpParams,
} from '@angular/common/http';
import {
  BehaviorSubject,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  filter,
  first,
  map,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  InvokeStaticMethod,
  InvokeStaticMethodAsync,
  InvokeStaticMethodEvent,
  argAsBool,
  argAsFloat,
  argAsInt,
  argAsString,
  login,
  loginSSO,
  logoutUser,
  exitApp,
  pauseRuntime,
  resumeRuntime,
  pauseRuntimeAudioOutput
} from '@istation-hydra/runtime-wasm/JSWasmApi.js';
import {
  LogFile,
  StudentSubject,
  StudentSubjectIsip,
  User,
  RuntimeToken,
  SceneEventDetail,
  InvokeResponseResult,
  InvokeResponseDateResult,
  InvokeResponseUserResult,
  DictionaryUserValue,
  DictionarySubjectValue,
  InvokeResponseSubjectResult,
  CustomEventNavigate,
  CustomEventLoginStateChange,
  CustomEventSceneEvent,
} from '@swe/types';
import {
  Grades,
  IstationSubjects,
  KnowledgeBaseKeys,
  WasmMenuActivities,
} from '@swe/enums';
import {
  ConfigurationService,
  FeatureFlagService,
  KnowledgeBaseService,
  UserService,
} from './';
import paths from '../data/routing-paths';
import { 
  EventDispatch,
  SubjectFactory,
  SubjectCard,
} from '../classes';
import { getNumStars, buildRuntimeSSOAuthUri, getIstationSubject } from '@swe/shared/utilities';
import { MessageService } from '@swe/features/message-popup/message.service';
import subjectsInfo from '@swe/data/subjects-info';
import { ErrorSample } from '@swe/data/samples';

@Injectable({
  providedIn: 'root',
})
export class WasmService implements OnDestroy {
  private NO_DISPLAY_ACTIVITIES = [
    paths.WasmStartup,
  ];
  private INVALID_PATH = 'Invalid path';
  private REAL_ERRORS = [
    this.INVALID_PATH,
  ];

  readonly wasmApiClass: string = 'com.istation.WasmApi';
  readonly supervisorClass: string = 'com.istation.Supervisor';
  readonly sysClass: string = 'com.istation.Sys';
  readonly menuActivities: string[] = [WasmMenuActivities.LinearPath, WasmMenuActivities.ISIP];
  readonly activityWebPath: string = 'activity';
  readonly jumpScene: string = 'QA_Debug.Adv_Jump';


  loggedIn               = new BehaviorSubject<boolean>(false);
  showWasmBehavior       = new BehaviorSubject<boolean>(false);
  showDebugPanelBehavior = new BehaviorSubject<boolean>(false);
  canLaunchWasm          = new BehaviorSubject<boolean>(false);
  hasUserState: Observable<boolean>;
  inStartupScene         = new BehaviorSubject<boolean>(false);
  runtimeVersion         = new BehaviorSubject<string>('0.0.0.0');
  accessToken            = new BehaviorSubject<string>('');
  logFiles               = new BehaviorSubject<Array<LogFile>>([]);
  currentTheme           = 0;
  userOid                = 0;
  currentStars           = 0;
  exitOnLogout           = true;
  isShuttingDown         = false;
  isLoggingOut           = false;
  isWasmReady            = false;
  isOnTouchDevice        = false;
  isLoading              = false;
  activityExecuted       = '';
  activityReturnPath     = '';
  needsIntro             = false;
  runtimeToken!: RuntimeToken;
  onlogoutCallback: () => (void | Promise<void>) = async () => {};
  sceneQueue: {
    sceneStart: Array<()=>void>,
    sceneEnter: Array<()=>void>,
    sceneExit: Array<()=>void>
  } = {
    sceneStart: [],
    sceneEnter: [],
    sceneExit: []
  }
  hasUnsavedData         = false; //true if we're in the middle of a wasm activity, false if we're idle in wasmstartup.
  allowAutomation = false;

  // Track runtime scene/activity state
  sceneStarted = new Subject<string>();
  sceneEntered =  new Subject<string>();
  sceneExited = new Subject<string>();

	// So we can stop pulling our hair over the unit test
  InvokeStaticMethodRef = InvokeStaticMethod;
  InvokeStaticMethodAsyncRef = InvokeStaticMethodAsync;
  InvokeStaticMethodEventRef = InvokeStaticMethodEvent;
  argAsBoolRef = argAsBool;
  argAsFloatRef = argAsFloat;
  argAsIntRef = argAsInt;
  argAsStringRef = argAsString;
  loginRef = login;
  loginSSORef = loginSSO;
  logoutUserRef = logoutUser;
  exitAppRef = exitApp;
  pauseRuntimeRef = pauseRuntime;
  resumeRuntimeRef = resumeRuntime;
  pauseRuntimeAudioOutputRef = pauseRuntimeAudioOutput;

  // Track subscriptions for destruction
  private subscriptions = new Subscription();

  constructor(
    private userService: UserService,
    private router: Router,
    private httpClient: HttpClient,
    private dispatch: EventDispatch,
    private configService: ConfigurationService,
    public message: MessageService,
    public featureFlags: FeatureFlagService,
    private kbService: KnowledgeBaseService
  ) {
    // Pause the runtime when the window loses focus
    const runtimePauseListener$ = fromEvent(window, 'blur')
      .pipe(filter(() => !this.allowAutomation && this.showWasmBehavior.value))
      .subscribe(() => {
        this.pauseRuntimeRef(true);

        // Suspend the runtime's AudioContext and allow the runtime to resume it.
        // This prevents some audio issues in Safari.
        this.pauseRuntimeAudioOutputRef();
      });
    
    // Pause the runtime and suspend its AudioContext when the Howler AudioContext is suspended or interrupted.
    // This allows the runtime to resume its AudioContext and prevents lost audio in iPad Safari.
    const sceneStartListener$ = this.sceneStarted.pipe(first(() => !!Howler.ctx)).subscribe(() => {
      this.subscriptions.add(
        fromEvent(Howler.ctx, 'statechange')
          .pipe(filter(() => {
            const state: string = Howler.ctx.state;
            return this.showWasmBehavior.value && (state === 'suspended' || state === 'interrupted')
          }))
          .subscribe(() => {
            this.pauseRuntimeRef(true);
            this.pauseRuntimeAudioOutputRef();

            // Resume the Howler AudioContext to continue handling state changes.
            Howler.ctx.resume();
          })
        );
    });

    this.hasUserState = this.accessToken.pipe(
      map(at => !!at),
      filter(isActive => isActive),
    );

    this.sceneStarted.pipe(filter(s => s === 'WASMStartup')).subscribe(()=>this.inStartupScene.next(true));
    this.sceneExited.pipe(filter(s => s === 'WASMStartup')).subscribe(()=>this.inStartupScene.next(false));

    //Navigate is an event sent from Powerpath Main Menu to inform the webpage when and where to navigate.
    const navigateEventListener$ = fromEvent<CustomEventNavigate>(document, 'Navigate')
      .pipe(
        tap(() => this.exitFullscreen()),
        filter(({detail}) => detail === 'Home' || detail.includes('ISIP') || detail === 'ReturnRoute')
      )
      .subscribe( async ({detail}) => {
        const defaultPath = `home`;
        if (
          detail === 'ReturnRoute' 
          || detail.includes('ISIP')
        ) {
          if (detail.includes('ISIP')) {
            console.warn('Navigate->ISIP from WASM is deprecated! The joe script that is calling this should be updated to use ReturnRoute');
            const initialName = detail.substring(0, detail.lastIndexOf('ISIP')).toLowerCase();
            const name = this.subjectNameWasmToOlp(initialName).toLowerCase();
            this.activityReturnPath = `${name}/isip/results/progress/`;
          }
          let path = this.activityReturnPath;
          this.activityReturnPath = ''; // reset the return path
          if (path.includes('isip')) {
            const hasCompletedIsip = await this.getTransientData(KnowledgeBaseKeys.MainMenuDirectory, KnowledgeBaseKeys.CompletedIsip);
            if (hasCompletedIsip === 'true') {
              const subjectName = this.subjectFromRoute(path);
              // Update user data
              const { subscription, subjectCards } = await this.generateUserDataObject();
              subjectCards.forEach(s => s.isNeeded = s.name.toLowerCase() === subjectName ? false : s.isNeeded);
              this.userService.userSubscription = subscription;
              this.userService.userSubjects.next(subscription.subjects);
              this.userService.userIsips.next(subscription.isips);
              this.userService.currentSubjects.next(subjectCards);
              this.setTransientData(KnowledgeBaseKeys.MainMenuDirectory, KnowledgeBaseKeys.CompletedIsip, false);
              const isValid = await this.addStarsFromLastTestIfValid(subjectName);
              if (!isValid) {
                console.error(`Invalid ISIP data for [${subjectName}]!`);
                path = `/${defaultPath}`;
              }
              this.router.navigateByUrl(path);
            } else {
              this.router.navigateByUrl(path);
            }
          } else {
            this.getUserStarsAsync()
              .then(stars => this.userService.user.next(Object.assign({}, this.userService.user.getValue(), {stars})))
              .finally(() => (path && this.router.navigateByUrl(path)));
          }
          this.hasUnsavedData = false;
          this.setShowWasm(false);
        } else if (detail === 'Home') {
          console.warn('Navigate->Home from WASM is deprecated! The joe script that is calling this should be updated to use ReturnRoute');
          this.hasUnsavedData = false;
          this.router.navigate([defaultPath]);
          this.setShowWasm(false);
        } else { // Received a Home Navigation event
          this.hasUnsavedData = false;
          this.router.navigate([defaultPath]);
        }
      });

    // Listen to any Login state changes from the runtime
    const loginStateChangeListener$ = fromEvent<CustomEventLoginStateChange>(document, "LoginStateChange")
        .pipe(
          filter(({detail: {State: state}}) => state !== 'Ready' || (state === 'Ready' && !this.isShuttingDown))
        )
        .subscribe(({detail: {State: state, Status: status}}) => {
          //console.log("*** LoginStateChange: " + JSON.stringify(event.detail));
          if (state === 'Ready') { // Runtime WASM app is ready
            this.isWasmReady = true;
            this.logFiles.next(window._isapp_module?.getLogFiles() || []);
            // console.log("We're ready to log in, token is :> " + this.runtimeToken?.runtimeToken);
            if (this.runtimeToken?.runtimeToken && this.runtimeToken.userOid) {
              //token needs some extra fluff for the login to succeed.
              const fullToken = buildRuntimeSSOAuthUri(this.runtimeToken);
              //console.log(`Login ready, trying login with token: ${fullToken}`); // NOTE: This token should NEVER be printed in Production
              this.loginSSO(fullToken);
            }
          }
          else if (state === 'NotReady') {
            this.isWasmReady = false;
          }
          else if (state === 'LoginFail') {// sso login failed
            const errorMask = 1100;
            this.message.showMessage(errorMask + status);
          }
          else if (state === 'LoginSuccess') {// Runtime token has been passed to Runtime WASM app and successfully authenticated
            //We've succeeded on the login, get the user data from the runtime.
            this.loggedIn.next(true);
            this.setUserFromKB();
            this.setupWasmVariables(this.currentTheme);
            this.getAppVersion()
              .then(v => this.runtimeVersion.next(v))
          }
          else if (state === 'Logoff') { // Runtime has initiated a logoff flow
            this.loggedIn.next(false);
            if (!this.exitOnLogout) {
              // you can logout without exiting the app if you desire
              if (typeof this.onlogoutCallback === 'function') {
                // the user has been logged off, notify as a callback
                this.onlogoutCallback();
              }
            } else {
              // close the app and wait for OnAppExit
              this.exitApp();
            }
          }
          else if (state === 'OnAppExit') { // the app exited
            this.loggedIn.next(false);
            if (this.exitOnLogout && typeof this.onlogoutCallback === 'function') {
                // the user has been logged off, notify as a callback
                this.onlogoutCallback();
            }
          }
        });

    const sceneEventListener$ = fromEvent<CustomEventSceneEvent>(document, "SceneEvent")
      .pipe(filter(() => !this.isShuttingDown))
      .subscribe(event => {

        // This is a temporary patch to create the correct elements prior to an object instead of a serialized string being passed.
        const {
          Delegate: sceneEventDelegate,
          SceneName: sceneEventName
        }: SceneEventDetail = typeof event.detail === 'string' ? JSON.parse(event.detail) : event.detail;
        const queue: Array<()=>void> = [];
        // Get the queue
        this.sceneStarted.next(sceneEventDelegate === "OnSceneStart" ? sceneEventName : undefined);
        this.sceneEntered.next(sceneEventDelegate === "OnSceneEnter" ? sceneEventName : undefined);
        this.sceneExited.next(sceneEventDelegate  === "OnSceneExit"  ? sceneEventName : undefined);
        switch (sceneEventDelegate) {
          case "OnSceneStart":
            Object.assign(queue, this.sceneQueue.sceneStart);
            this.sceneQueue.sceneStart = [];
            if(this.isLoading) {
              if(this.activityExecuted === WasmMenuActivities.ProductSelect ||
                this.activityExecuted === WasmMenuActivities.IntroToComputers ||
                (this.activityExecuted === WasmMenuActivities.Modeling && sceneEventName === 'ExploreScreen') ||
                this.activityExecuted === '') {
                  this.hideLoadingScreen();
              }
            }
            break;
          case "OnSceneEnter":
            Object.assign(queue, this.sceneQueue.sceneEnter);
            this.sceneQueue.sceneEnter = [];
            break;
          case "OnSceneExit":
            Object.assign(queue, this.sceneQueue.sceneExit);
            if(this.isLoading &&
              sceneEventName === "MainMenu" &&
              this.menuActivities.includes(this.activityExecuted)){
                this.hideLoadingScreen();
            }
            this.sceneQueue.sceneExit = [];
            break;
        }

        // run a queue of events for this trigger
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        while (queue.length > 0) { (queue.shift()!)(); }
      });

    this.accessToken.subscribe(() => {
      if (this.userOid !== 0) {
        this.getRuntimeToken(this.userOid)
          .subscribe((newAccessToken) => {
            if (newAccessToken?.runtimeToken) {
              //cache runtime token so we can login when the runtime has spun up completely.
              this.runtimeToken = newAccessToken;
            }
            else {
              console.error("We've got an invalid new runtime token.");
            }
          });
      }
    });

    this.userService.stars.subscribe((numStars) => {
      //if we're trying to save the same numStars, ignore it and return.
      if (numStars === -1 || numStars === this.currentStars) return;

      this.currentStars = numStars;
    });

    this.userService.theme.subscribe((theme) => {
      switch (theme) {
        case 'wavy':
          this.currentTheme = 1;
          break;
        case 'spacecat':
          this.currentTheme = 2;
          break;
        case 'gamerz':
          this.currentTheme = 3;
          break;
        case 'synthwave':
          this.currentTheme = 4;
          break;
      }
      if (this.loggedIn.getValue()) {
        this.setupWasmVariables(this.currentTheme);
      }
    });

    this.subscriptions.add(runtimePauseListener$);
    this.subscriptions.add(sceneStartListener$);
    this.subscriptions.add(navigateEventListener$);
    this.subscriptions.add(loginStateChangeListener$);
    this.subscriptions.add(sceneEventListener$);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // #region Wasm Navigation
  /**
   * Executes an activity in WASM.
   * 
   * @param path       the routing path within WASM
   * @param returnPath the SE path to return to after the WASM activity completes
   * @returns number promise
   */
  executeActivity(path: string, returnPath: string = ''): Promise<void | number> {
    const doShow = !this.NO_DISPLAY_ACTIVITIES.includes(path);
    const webPath = doShow ? this.activityWebPath: '';
    const args = [this.argAsStringRef(path)];
    this.isLoading = true;
    this._prepForActivity(doShow, returnPath, path);
    return this._isValidPath(args)
      .then(() => this._executeActivity(args))
      .then(() => this._navigateForActivity(webPath))
      .catch((err) => {
        if (!this._handleExecuteError(err, false)) {
          this._navigateForActivity(webPath);
        }
      });
  }

  /**
   * Wasm executes the activities within the range provided
   * @param start Start path of the range to execute
   * @param stop Stop path of the range to execute
   * @param stopBefore true to stop before the stop path, false to stop after it
   * @param returnPath the SE path to return to when the activity is complete
   * @returns promise
   */
  executeActivityRange(start: string, stop: string, stopBefore: boolean, returnPath: string = ''): Promise<void> {
    const args = [this.argAsStringRef(start), this.argAsStringRef(stop), this.argAsBoolRef(stopBefore)];
    this.isLoading = true;
    const webpath = !this.router.url.includes('welcome') ? this.activityWebPath : '';
    this._prepForActivity(true, returnPath, start);
    return this._isValidPath([this.argAsStringRef(start)])
      .then(() => this._isValidPath([this.argAsStringRef(stop)]))
      .then(() => this._executeActivityRange(args))
      .then(() => this._navigateForActivity(webpath))
      .catch((err) => {
        if (!this._handleExecuteError(err, true)) {
          this._navigateForActivity(webpath);
        }
      });
  }

  /**
   * runs executeActivityRange on WasmStartup path.
   */
  executeStartup(): void {
    this.executeActivity(paths.WasmStartup);
  }

  /**
   * Open the Jump scene on the activity page
   */
  async openJumpScene(): Promise<void> {
    if (!this.isOpenJumpSceneAllowed()) {
      console.error('openJumpScene(): Not allowed from ' + this.router.url);
      return Promise.reject();
    }
    
    this.activityReturnPath = 'home';
    const args = [this.argAsStringRef(this.jumpScene)];
    return this.InvokeStaticMethodAsyncRef(this.sysClass, 'setScene', args)
      .then(() => {
        this.setShowWasm(true);
        this.router.navigate(['activity']);
      })
      .catch((err: string) => console.error(this.sysClass + '.setScene(): ' + err));
  }

  /**
   * Checks that a given routing path is valid.
   * 
   * @param path the routing path to check
   * @returns true if the path is valid, false otherwise
   */
  public isValidPath(path: string): Promise<boolean> {
    // We'll just assume that an error is a bad path
    return this._isValidPath(this.argAsStringRef(path))
      .then(
        () => Promise.resolve(true),
        () => Promise.resolve(false)
      )
      .catch(() => Promise.resolve(false));
  }

  private getCurrentPath(): Promise<string> {
    return this.InvokeStaticMethodAsyncRef(this.supervisorClass, 'GetCurrentActivityName')
      .then(
        (result: InvokeResponseResult) => result.value,
        () => ''
      );
  }

  /**
   * Internal use only execution of an activity path. Only call this if you know
   * the path is good!
   * 
   * @param path argAsString'd path to execute
   */
  private _executeActivity(path: InvokeResponseResult[]): Promise<number> {
    console.warn('executing', {path});
    return this.InvokeStaticMethodAsyncRef(this.supervisorClass, 'Execute', path)
  }

  /**
   * Internal use only execution of an activity range. Only call tis if you 
   * know the path is good!
   * 
   * @param args 
   * @returns 
   */
  private _executeActivityRange(args: InvokeResponseResult[]): Promise<boolean> {
    return this.InvokeStaticMethodAsyncRef(this.supervisorClass, 'ExecuteActivityRange', args);
  }

  /**
   * Internal only check that a given routing path is valid.
   * 
   * @param path the routing path to check
   * @returns resolves to true if the path is valid, throws an error otherwise
   */
  private _isValidPath(path: string[]): Promise<boolean> {
    return this.InvokeStaticMethodAsyncRef(this.supervisorClass, 'PathExists', path)
      .then((result: InvokeResponseResult) => {
        if (Number(result.value)) {
          return Promise.resolve(true);
        } else {
          throw new Error(this.INVALID_PATH);
        }
      });
  }

  /**
   * Sets needed state for when we launch an activity in WASM. This should only 
   * be called after we know we're actually launching the activity
   *
   * @param doShow true to show wasm, false otherwise
   * @param returnPath the url to return to when done with the activity
   * @param path the routing path of the activity
   */
  private _prepForActivity(doShow: boolean, returnPath: string, path: string): void {
    // Skip setting hasUnsavedData flag and activityReturnPath if returning to WasmStartup
    if (path !== paths.WasmStartup) {
      this.hasUnsavedData = true;
      this.activityReturnPath = returnPath || this.router.url;
    }
    this.setShowWasm(doShow);
  }

  /**
   * Navigates to the activity set by webPath. Should only be called after _prepForActivity
   *
   * @param webPath the url to navigate to while running the activity
   */
    private _navigateForActivity(webPath: string): void {
      webPath && this.router.navigateByUrl(webPath);
    }

  /**
   * Handle errors when attempting to execute an activity or activity range. Currently, we only
   * really care about invalid path errors, so we can get "false alarms". In the case of a false
   * alarm, we'll go ahead and still set things up as if everything is good.
   * 
   * @param err 
   * @param isRange true if the attempted execution is of a range
   * @return true if it was an actual error, false otherwise
   */
  _handleExecuteError(err: Error, isRange: boolean = false): boolean {
    // we only really care about Invalid Path errors at the moment
    if (!this.REAL_ERRORS.includes(err.message)) {
      console.warn(err);
      return false;
    }
    this.isLoading = false;
    this.activityReturnPath = '';
    this.setShowWasm(false);
    this.hasUnsavedData = false;
    const msg = `Canceling WASM activity ${isRange ? 'range ' : ''}execution:`;
    console.error(msg, err.message);
    return true;
  }
  // #endregion
  
  // #region Activity Rating and Stars
  /**
   * Gets the rating for an activity at a specific period
   * @param {string} activityName Name of the activity being rated
   * @param {number} period The period we want the rating for
   * @return {Promise<string>} The rating from the specified period
   */
  getActivityRating(activityName: string, period: number): Promise<string> {
    return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'getActivityRating', [this.argAsStringRef(activityName), this.argAsIntRef(period)])
      .then(({value}: InvokeResponseResult) => value)
      .catch((errString: string) => new Error(errString));
  }

  /**
   * Sets the rating of an activity for a specific period
   * @param activityName name of the activity being rated
   * @param value the rating
   * @param period period of the rating
   */
  setActivityRating(activityName: string, value: number, period: number): void {
    const args = [this.argAsIntRef(period), this.argAsStringRef(activityName), this.argAsIntRef(value)];
    this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'saveActivityRating', args)
    .catch((errStr : string) => { console.error("setActivityRating: " + errStr)});
  }

  /**
   * Sets the rating of an activity for the current period
   * @param activityName name of the activity being rated
   * @param value the rating
   */
  setActivityRatingCurrentPeriod(activityName: string, value: number): void {
    const args = [this.argAsStringRef(activityName), this.argAsIntRef(value)];
    this.InvokeStaticMethodAsyncRef(
      this.wasmApiClass,
      'setActivityRatingWithCurrentPeriod',
      args
    )
    .catch((errStr : string) => { console.error("setActivityRatingCurrentPeriod: " + errStr)});
  }

  /**
   * Gets the number of stars the user currently has
   * @returns the number of stars the user currently has
   */
  getUserStarsAsync(): Promise<number> {
    return this.kbService.get("MainMenu", "Stars").then(v => parseInt(v as string, 10));
  }

  /**
   * Adds stars for the user
   * @param {number} numStars number of stars to add for the user
   */
  async addUserStars(numStars: number): Promise<void> {
    if (numStars < 0) throw new Error('Number of stars less than 0');
    await this.kbService.setAndSync("MainMenu", "Stars", numStars);
    this.currentStars += numStars;
    this.userService.stars.next(this.currentStars);
  }

  /**
   * Calculate and adds the number of stars earned from the last ISIP
   * test for the given subject
   * @param subject string name of the ISIP subject to get stars for
   * @returns
   */
  private async addStarsFromLastTestIfValid(subject: string): Promise<boolean> {
    const subjectArray = this.userService.currentSubjects.getValue();
    const subjectInfo = subjectArray.find(subj => subj.name.toLowerCase() === subject.toLowerCase());
    if (subjectInfo) {
      const monthInfoArray = subjectInfo.isip.totalScores;
      const monthInfo = monthInfoArray?.[monthInfoArray.length - 1]; // monthInfoArray.pop();
      if (!monthInfo) {
        return false;
      }
      const { period: lastPeriod, value: scoreValue } = monthInfo;
      const goal = subjectInfo.isip.goals.find(({period: subjectGoalPeriod}) => subjectGoalPeriod === lastPeriod);
      const goalValue = goal ? Math.round(goal.value) : -1;
      await this.addUserStars(getNumStars(scoreValue, goalValue, subjectInfo.isip.totalScores.length === 1));
      return true;
    }
    return false;
  }
  // #endregion
  
  // #region Login/Logout/Exit
  /**
   * USE LOGIN SSO
   * Login user with username and password.
   * Login results are forwarded asynchronously to LoginStateChange event
   * @param user username for login
   * @param pass password for login
   * @param domain domain to login to
   */
  login(user: string, pass: string, domain: string): void {
    if (this.isWasmReady === false) {
        throw new Error("IRuntime is not ready for logging in");
    }
    this.loginRef(user, pass, domain);
  }

 /**
   * Login user using sso token
   * Login results are forwarded asynchronously to LoginStateChange event
   * @param ssotoken SSO token to log into the runtime with
   * @returns Login Results
   */
  loginSSO(ssotoken: string): void {
    if (this.isWasmReady === false) {
      throw new Error("IRuntime is not ready for logging in with SSO");
    }
    this.loginSSORef(ssotoken);
  }

  /**
   * Logout the user and conditionally shutdown wasm
   * Logout results are forwarded asynchronously to LoginStateChange event
   * @param bExit true to shutdown wasm after logout, false to keep it running
   * @param logoutCB Callback to run when user logout is complete
   */
  async logout(bExit: boolean, logoutCB?: (args: void) => void): Promise<void> {
    if (this.isLoggingOut) return;
    this.isLoggingOut = true;
    this.exitOnLogout   = bExit;
    this.isShuttingDown = bExit;
    this.removeDebugItems();
    if (logoutCB) this.onlogoutCallback = logoutCB;
    if (!this.isWasmReady) {
        // wasm isn't running, just go straight to the callback
        this.onlogoutCallback();
        return;
    }
    const loggedIn = await this.isLoggedIn();
    if (loggedIn) {
        // start listening for worker ejection
        addEventListener('error', async (e) => {
          if (
            this.isLoggingOut 
            && this.isShuttingDown 
            && e.message.includes('RuntimeError')
            && typeof this.onlogoutCallback === 'function'
          ) {
            e.preventDefault();
            await this.onlogoutCallback();
          }
        });
        // kick off the asynchronous logout step (the onlogoutCallback will happen much later)
        this.logoutUserRef();
    } else {
        // the user isn't even logged in, just force the callback
        await this.onlogoutCallback();
    }
  }

  /**
   * Close the app
   */
  exitApp(): void {
    this.exitAppRef();
  }
  // #endregion
  
  // #region User Data
  /**
   * Generates user data from jovascript and
   * sets a new user in UserService
   */
  setUserFromKB(): Promise<void> {
    this.kbService.userID = this.userService.user.getValue()?.id.toString() || '';
    const $unsubInStartupScene = new Subject();
    // this.kbService.get("ISIP_Ratings", "lectura-isip").then(c => console.warn(`HERES the Supervisor:Reading value`, c));
    // this.kbService.get("Alpha_A", "LC_data").then(c => console.warn(`HERES the Supervisor:Reading value`, c));
    return new Promise<void>(resolve => {
      this.inStartupScene
        .pipe(
          takeUntil($unsubInStartupScene),
          filter(inScene => inScene)
        ).subscribe(() => {
          // console.log('WasmStartup Scene loaded')
          this.generateUserDataObject()
            .then((newUser) => {
              //console.log('%c Setting up user', 'background:#1d4848;color:white;', newUser);
              //save stars to disallow saving of the same values in the future
              if (newUser.stars) {
                this.currentStars = newUser.stars;
              }
              this.userService.user.next(newUser);
              this.userService.isLoaded.next(true);
              this.userService.userSubscription = newUser.subscription;
              this.userService.userSubjects.next(newUser.subscription.subjects);
              this.userService.userIsips.next(newUser.subscription.isips);
              this.userService.currentSubjects.next(newUser.subjectCards);
              this.userService.userVerbals.next(newUser.verbalCards);
              $unsubInStartupScene.next();
              resolve();
            });
        });
    });
  }

  /**
   * Request user data from Jovascript, then parse it into a User object.
   * @returns User parsed from Jovascript dictionary
   */
  async generateUserDataObject(): Promise<User & { subjectCards: SubjectCard[], verbalCards: SubjectCard[]}> {
    const data = await this.getUserData();
    const incomingUser = this.userService.user.getValue();
    const grade = await (
      incomingUser && incomingUser.grade !== Grades.None && typeof incomingUser.grade !== 'undefined'
        ? incomingUser?.grade
          : data.Grade?.value && !isNaN(Number(data.Grade.value))
            ? Number(data.Grade.value)
              : this.userService.getUserConfigGrade()
                  .then(v => v && !isNaN(Number(v)) ? parseInt(v, 10) : Grades.None, () => Grades.None));
    const band = this.userService.getBand(grade);
    const dataCaptureList = [
      'ReadingDictionary',
      'MathDictionary',
      'SpanishDictionary',
    ];
    if (this.featureFlags.isFlagEnabled('spanishMath')) dataCaptureList.push('SpanishMathDictionary');
    const {subjects, isips, subjectCards} = dataCaptureList
      .reduce<{
        subjects: StudentSubject[],
        isips: StudentSubjectIsip[],
        subjectCards: SubjectCard[],
      }>(({subjects, isips, subjectCards}, dictionaryKey) => {
        const subjectDictionary: InvokeResponseSubjectResult = data[dictionaryKey] as InvokeResponseSubjectResult;
        // isNeeded is set by if subjectDictionary.value.HasCurriculum.value === 'true'
        if(subjectDictionary && subjectDictionary.value) {
            const { 
              Name: {
                value: name
              }, 
              ISIP: { value: isipDictionary }
            } = subjectDictionary.value;
            let [subject, subjectIsip] = this.parseSubject(subjectDictionary.value, grade);

            // Reading is now customized for Amira
            let card : SubjectCard;
            if (this.featureFlags.isFlagEnabled('enableAmiraReadingHome') 
              && getIstationSubject(name) === IstationSubjects.reading) {
              // replace base card with amira tutor info
              const tutorName = 'ReadingTutor';
              card = new SubjectCard({name: tutorName, isipDictionary, band});
              card.parseIsip(name, isipDictionary, band);

              // add assignment info
              const assignmentName = 'ReadingAssignment';
              card.assignment = SubjectFactory.getSubject(assignmentName);
              //card.assignmentDue = false; // TODO: temp for forcing assignment due
            }
            else {
              card = new SubjectCard({name, isipDictionary, band});
            }

            card.isipOnly = subjectDictionary.value.HasCurriculum.value === 'false';
            subjects.push(subject);
            isips.push(subjectIsip);
            if (card.isGradeAppropriate(band)) {
              subjectCards.push(card);
            }
        }
        return {
          subjects,
          isips,
          subjectCards
        }
      }, {
        subjects: [],
        isips: [],
        subjectCards: [],
      });
    const verbalCards: StudentSubject[] = data.Subscriptions && data.Subscriptions.value
      ? Object.entries(data.Subscriptions.value)
          .reduce<Array<StudentSubject>>((acc, [key, {value}]) => {
            const v = new SubjectCard({name: key, band});
            if (v && value === 'true') acc.push(v);
            return acc;
          }, []) 
      : [];

    // Overall sort priority should be the same for the subject and its ISIP!
    SubjectFactory.sortSubjectsByPriority(subjectCards);
    SubjectFactory.sortSubjectsByPriority(subjects);
    SubjectFactory.sortSubjectsByPriority(isips);
    SubjectFactory.sortSubjectsByPriority(verbalCards);

    const newUserStars = await this.userService.getUserKbStars();
    const newUserAvatar = await this.userService.getUserKbAvatar();
    const newUser = <User & { subjectCards: SubjectCard[], verbalCards: SubjectCard[]}>(Object.assign({}, incomingUser, {
      stars: newUserStars,
      grade,
      isTeacher: data.Teacher.value === 'true',
      avatar: newUserAvatar,
      subjectCards,
      verbalCards,
      subscription: {
        subjects,
        isips
      },
    }));
    return newUser;
  }

  /**
   * Gets the user data dictionary from jovascript.
   * @returns Promise<UserResponse> Dictionary of user data
   */
  getUserData(): Promise<DictionaryUserValue> {
    return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'getUserData')
      .then(({value}: InvokeResponseUserResult) => value)
      .catch((errStr : string) => { console.error("getUserData: " + errStr)});
  }

  // #endregion
  
  // #region Transient Data
  /**
   * Sets up runtime variables needed by WASM main menu
   * @param theme the theme for the student
   */
  setupWasmVariables(theme: number): void {
    const args = [this.argAsIntRef(theme)];
    this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'setupWasmVariables', args)
    .catch((errStr : string) => { console.error("setupWasmVariables: " + errStr)});
  }

  getRuntimeToken(userOid: number): Observable<RuntimeToken> {
    const params = new HttpParams().set('userId', userOid);
    return this.httpClient
      .get<RuntimeToken>(
        `${this.configService.config.idServerURL}api/runtimetoken`, 
        { params }
      );
  }

  /**
   * Gets a key in transient data
   * @param section Transient section of the key
   * @param key Transient key to get
   * @param cb Callback to handle the transient data
   */
  async getTransientData(section: string, key: string): Promise<string> {
    const args = [this.argAsStringRef(section), this.argAsStringRef(key)];
    return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'getTransientData', args)
      .then(({value: v}: InvokeResponseResult) => v);  
  }

  /**
   * Sets a key in transient data
   * @param section Transient section of the key
   * @param key Transient key to set
   * @param value value to set the key to
   *              Currently accepts string, number, or boolean. Numbers treated as integers.
   */
  setTransientData(section: string, key: string, value: string | number | boolean): void {
    const args = [this.argAsStringRef(section), this.argAsStringRef(key)];
    if (typeof value === 'string') {
      args.push(this.argAsStringRef(value));
    } else if (typeof value === 'number') {
      args.push(this.argAsIntRef(value));
    } else if (typeof value === 'boolean') {
      args.push(this.argAsBoolRef(value));
    }
    this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'setTransientData', args)
    .catch((errStr : string) => { console.error("setTransientData: " + errStr)});
  }
  // #endregion
  
  // #region Session/User Info/Status
  /**
   * Gets the version of the wasm runtime
   * @returns string representing runtime version
   */
  getAppVersion(): Promise<string> {
    return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'GetRuntimeVersion')
      .then(({value: v}: InvokeResponseResult) => v)
      .catch((errStr : string) => { console.error("getAppVersion: " + errStr)});
  }

  /**
   * Determine if opening the Jump scene should be allowed,
   * based on the current URL
   */
  isOpenJumpSceneAllowed(): boolean {
    return this.router.url.split('/').pop() !== 'activity';
  }

  /**
   * Determine if the student needs intro to computers
   * @returns true if the student needs intro to computers, false if they don't
   */
  getNeedsIntro(): Promise<boolean> {
    return this.kbService.get("IntroToComputers", "bHasSeenPC").then((v) => {
      this.needsIntro = !!parseInt(v as string, 2);
      return !this.router.url.includes("welcome") && this.needsIntro;
    });
  }

  /**
   * Get if the user is currently logged in.
   * @returns Promise<boolean>
   */
  isLoggedIn(): Promise<boolean> {
    return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'isLoggedIn')
        .then(
          (result: InvokeResponseResult) => result.value === '1' ? true : false,
          () => false // assume not logged in if there was an error
        );
  }

  /**
   * Returns the effective date.
   *
   * Okay, this is cheating a little. We're setting the effective date here
   * via WasmService, and instead of making a new method in joe-land and using
   * the WasmAPI, I saved the altered date to the session storage. This method
   * grabs and parses that date.
   *
   * If it was not saved in session storage, then we have not altered the date
   * via debug time traveling magic, and we return today's date instead.
   * @see setEffectiveDate
   *
   * @returns
   */
  getEffectiveDate(): Date {
    const savedDate = sessionStorage.getItem("EffectiveDate");	// EPOCH TIME!!
    const effectiveDate = savedDate ? new Date(Number(savedDate)) : new Date();
    // console.log(`%c EffectiveDate (get) sessionStorage-value[${savedDate}] toDateString[${effectiveDate}]`, "background:mediumpurple;color:white;");
    return effectiveDate;
  }

  hasEffectiveDate(): boolean {
    return !!sessionStorage.getItem("EffectiveDate");
  }

  /**
   * Gets the current state of a setting
   * @param {string} settingName Setting name to get
   * @returns the setting state
   */
  getUserSetting(settingName: string): Promise<string> {
    return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'getUserSetting', [this.argAsStringRef(settingName)])
      .then(({value}: InvokeResponseResult) => value)
      .catch((errStr : string) => { console.error("getUserSettingAsync: " + errStr)});
  }

  /**
   * Sets user setting
   * @param settingName Name of the setting being set
   * @param setting What the setting is being set to
   *                Currently supports number, boolean, and string
   * @param isNumberInt optional true if setting should be an int, false if the number is a float
   */
  setUserSetting(
    settingName: string,
    setting: number | boolean | string,
    isNumberInt?: boolean //default to float, pass boolean if it's supposed to be an int.
  ): void {
    const args = [this.argAsStringRef(settingName)];
    if (typeof setting === 'number') {
      if (!isNumberInt) {
        args.push(this.argAsFloatRef(setting));
      } else {
        args.push(this.argAsIntRef(setting));
      }
    } else if (typeof setting === 'boolean') {
      args.push(this.argAsBoolRef(setting));
    } else {
      args.push(this.argAsStringRef(setting));
    }
    this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'setUserSetting', args)
    .catch((errStr : string) => { console.error("setUserSetting: " + errStr)});
  }
  // #endregion
  
  // #region Conversions
  /**
   * Parse Jovascript's boolean object
   * @param boolValue Jovascript dictionary object (not .value)
   *                  ex. {type: 'vt_bool', value: 'true'}
   * @returns true if the dictionary value is true, false if not.
   */
  parseBoolean(boolValue: InvokeResponseResult): boolean {
    return boolValue.value === 'true';
  }

  /**
   * Parse Jovascript's date object
   * @param dateValue Jovascript date object (not .value)
   *                  ex. {type: 'vt_time', value: {day: 'blah', month: 'ect...'}}
   * @returns Date if it's a valid date, Undefined if it's not
   */
  parseDate(dateValue: InvokeResponseDateResult): Date | undefined {
    return SubjectFactory.parseDate(dateValue);
  }

  /**
   * parse a subject from it's dictionary
   * @param dictionary dataFromJovascript.ProductDictionary.value
   * @returns tuple parsed [StudentSubject, StudentSubjectIsip]
   */
  parseSubject(dictionary: DictionarySubjectValue, grade: number): [StudentSubject, StudentSubjectIsip] {

    const name = dictionary.Name.value;
    const gradeBand = this.userService.getBand(grade);
    const subject = SubjectFactory.getSubject(name, this.parseBoolean(dictionary.HasCurriculum), gradeBand);
    const isip = SubjectFactory.getIsip(name, dictionary.ISIP.value, gradeBand, grade);

    if (subject && isip) {
      subject.related = isip._name;
      isip.related = subject._name;
    }

    const isIsipOnly = !this.parseBoolean(dictionary.HasCurriculum);
    subject.isIsipOnly = isIsipOnly;
    isip.isIsipOnly = isIsipOnly;

    return [subject, isip];
  }

  subjectFromRoute(route: string): string {
    // this will need to change when app url path structure is changed
    const s = route.split('/');
    // in case we have a rout that starts with '/'
    return s[0] || s[1] || '';
  }

  subjectNameWasmToOlp(name: string): string{
    return name.toLowerCase() === 'spanish' ? 'Lectura' : name;
  }

  subjectNameOlpToWasm(name: string): string{
    let s = name;
    const lower = name.toLowerCase();
    if (lower === 'lectura') {
      s = 'Spanish';
    } else if (lower === 'matemáticas') {
      s = 'SpanishMath';
    }
    return s;
  }
  // #endregion
  
  // #region Wasm Display
  /**
   * Next showWasmBehavior to show/hide wasm.
   * @param show true to show, false to hide
   */
  setShowWasm(show: boolean): void {
    this.showWasmBehavior.next(show);
  }

  hideLoadingScreen() {
    this.isLoading = false;
    this.activityExecuted = '';
  }
  // #endregion
  
  // #region Misc
  @HostListener('touchstart', ['$event'])
  setTouchDevice(): void{
    this.isOnTouchDevice = true;
  }

  /**
   * Record activity on the currently running scene in joe.
   */
  recordActivity(): void {
    this.InvokeStaticMethodEventRef(this.wasmApiClass, 'recordActivity', []);
  }
  // #endregion
  
  // #region Debug
  makeLog(logMsg: string, numLogs: number, includeCurrent: boolean): void {
    const args = [this.argAsStringRef(logMsg), this.argAsIntRef(numLogs), this.argAsBoolRef(includeCurrent)];
    this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'recordLogs', args)
    .catch((errStr : string) => { console.error("makeLog: " + errStr)});
  }

  /**
   * next showDebugPanelBehavior to show/hide the debug panel
   * @param show true to show, false to hide
   */
  setShowDebugPanel(show: boolean): void {
    this.showDebugPanelBehavior.next(show);
  }

  /**
   * Removes any saved info (like in sessionStorage) used for debugging.
   * @see logout
   */
  removeDebugItems(): void {
    sessionStorage.removeItem("EffectiveDate");
  }

  /**
   * Calls document.exitFullscreen() if it exists in document
   */
  exitFullscreen(): void {
    if (document.fullscreenElement) {
      document.webkitExitFullscreen ? document.webkitExitFullscreen() : document.exitFullscreen();
    }
  }

  /**
  * DEBUG USE ONLY
  * Sets the server skew to reflect the requested date
  * @see getEffectiveDate
  * @param month Month integer (0 based)
  * @param day Day integer (0 based)
  * @param year Year integer (4 digit gregorian years, ex: 2022)
  */
  setEffectiveDate(month: number, day: number, year: number): Promise<void>{

    if ((month >= 0 && month < 12) &&
        (day >= 0 && day < 31) &&
        (year >= 1900 && year < 2100)) {

        const args = [this.argAsIntRef(month), this.argAsIntRef(day), this.argAsIntRef(year)];
        return this.InvokeStaticMethodAsyncRef(this.wasmApiClass, 'setToDate', args).then(() => {
          if(this.loggedIn.value){
            // if we're logged in we need to fetch user again, if not it will resolve when we log in.
            this.setUserFromKB();

            const effectiveDate = new Date(year, month, day);
            effectiveDate.setMonth(effectiveDate.getMonth()-1);	// to offset craziness
            effectiveDate.setDate(effectiveDate.getDate());
            // now saving the Unix epoch time...
            sessionStorage.setItem("EffectiveDate", effectiveDate.getTime().toString());
            // console.log(`%c EffectiveDate (set) toDateString[${effectiveDate.toDateString()}] getTime[${effectiveDate.getTime()}]`, "background:mediumpurple;color:white;");
          }
        });
    }
    else {
      console.error("setEffectiveDate: invalid args");
      return Promise.reject();
    }
  }
  // #endregion
}
