import { Injectable } from '@angular/core';
import { APIHandler } from '../classes/api-handler';
import { Observable, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ExternalAssessmentEligibilityDto } from '../types/external-assessment-eligibility.dto';
import { UserService } from './user.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class AmiraISIPPilotService {
  private apiHandler: APIHandler;
  private apiUrl: string;
  private userService: UserService;

  constructor(userService: UserService, configService: ConfigurationService) {
    this.apiUrl = configService.config.isipPilotServerURL;
    this.apiHandler = new APIHandler({
      baseUrl: this.apiUrl,
    });
    this.userService = userService;
  }

  getAssessmentEligibility(skillName: string = 'pilotAssessment', monthlyPeriod: string = 'APRIL'): Observable<string> {
    const userId = this.userService.id;
    const path = `${userId}`;
    const params = {
      skillName,
      monthlyPeriod,
    };

    return from(this.apiHandler.get<ExternalAssessmentEligibilityDto>(path, { params }))
      .pipe(
        map(response => {
          return response?.externalAssessment?.assessmentGuid;
        }),
        catchError(error => {
          console.error('Error fetching assessment eligibility:', error);
          throw error;
        })
      );
  }
}