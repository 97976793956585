import { inject, Injectable } from "@angular/core";
import Queries from "@swe/data/queries/amira-sis-queries";
import { MessageCodes } from "@swe/enums";
import { MessageService } from "@swe/features/message-popup/message.service";
import { AmiraSISGetUserInput, AmiraSISGetUserResponse } from "@swe/types/amira-sis-types";
import { Apollo, ApolloBase } from "apollo-angular";

@Injectable({
    providedIn: 'root',
})
export class AmiraSISService {
    public apollo: ApolloBase;

    private messageService: MessageService = inject(MessageService);
    
    constructor(private apolloProvider: Apollo) {
        this.apollo = apolloProvider.use('amiraSIS');
    }
    
    async getUserMetaIDs(input: AmiraSISGetUserInput): Promise<AmiraSISGetUserResponse | void> {
        return this.apollo.query<{ student: AmiraSISGetUserResponse }>({
            query: Queries.GET_USER,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            if (result.errors) {
                console.error('Error getting user meta IDs:', result.errors.map(e => e.message));
                throw new Error('User meta IDs query failed.');
            }

            if (
                !result.data?.student?.districtId ||
                result.data?.student?.districtId === "" ||
                !result.data?.student?.schoolId ||
                result.data?.student?.schoolId === ""
            ) {
                throw new Error('User meta IDs not found.');
            }

            return {
                schoolId: result.data.student.schoolId.toString(),
                districtId: result.data.student.districtId.toString()
            };
        }).catch((error) => {
            this.messageService.handleError(MessageCodes.HTTP_UNEXPECTED_NET_ERROR);
        });
    }
}