<nav class="nav" [ngClass]="{'student-inbox': isStudentInbox}">
  <ng-container *ngIf="!showBackButton; else backButton">
    <div class="nav-logout">
      <button class="focus-outline"
              tabindex="0"
              (click)="logoutPopup()">Log out</button>
    </div>
    <ng-container *ngIf="!isStudentInbox">
      <div class="logo-border"></div>
    </ng-container>
    <h1>Istation</h1>
  </ng-container>
  <ng-template #backButton>
    <button
      type="button"
      class="btn btn-tertiary nav-back-button"
      sweAudioClick
      aria-label="Back Button"
      [audioType]="clickType"
      [descriptor]="clickBack"
      (click)="goBack()"
    >
      <svg-icon src="assets/icons/back.svg"></svg-icon>
      <span
        *ngIf="backButtonText"
        class="button-text"
      >
        {{ backButtonText }}
  </span>
    </button>
  </ng-template>
  <span class="nav-alert"></span>
  <span *ngIf="showAmiraIsipPilotButton" class="nav-amira-isip-pilot-button-container">
    <button
      class="btn nav-amira-isip-pilot-button"
      type="button"
      sweAudioClick
      [audioType]="clickType"
      [descriptor]="clickGo"
      (click)="launchAmiraIsipPilot()"
      aria-label="Amira ISIP Pilot Button"
    >
      <svg-icon [src]="amiraIsipPilotButtonIcon"></svg-icon>
      <span class="button-text">Amira ISIP Pilot</span>
    </button>
  </span>
  <button
    class="btn btn-system-tertiary nav-stars-btn"
    type="button"
    sweAudioHint
    [hintType]="hintType"
    [hintDesc]="hintDesc"
    [attr.aria-label]="'Rewards: ' + stars + ' stars'"
  >
    <swe-nav-stars
          *ngIf="isLoaded && stars >= 0"
          [numStars]="stars"
    ></swe-nav-stars>
  </button>
  <button
    id="open-profile-menu"
    class="btn btn-system-primary nav-avatar"
    type="button"
    sweAudioClick
    aria-label="Profile settings menu"
    aria-controls="profilePopup"
    aria-haspopup="true"
    [attr.aria-expanded]="userMenuVisible"
    [audioType]="clickType"
    [descriptor]="clickMenu"
    sweTracking="open_profile_menu"
    (click)="toggleUserMenu()"
  >
    <swe-avatar *ngIf="avatar !== -1; else hamburger"
                [avatar]="avatar"
                [flair]="'circle'"
                [bkgdColor]="'#ffffff'"></swe-avatar>
    <ng-template #hamburger>
      <div class="nav-hamburger"></div>
    </ng-template>
  </button>

  <swe-profile-popup
    *ngIf="userMenuVisible"
    id="profilePopup"
    role="menu"
    aria-label="Profile settings"
    aria-activedescendant="btn-avatar-chooser"
    [attr.aria-hidden]="userMenuVisible"
    [avatar]="avatar"
    [studentName]="(this.userService.givenName | async) + ' ' + (this.userService.familyName | async | initial)"
    [showProfile]="userMenuVisible"
    (showProfileChange)="toggleUserMenu()"
    [(avatarChooser)]="avatarChooser"
    [(themeChooser)]="showThemeChooser"
    [(supportMenu)]="showSupportMenu"
    class="sys"
  ></swe-profile-popup>
</nav>

<swe-dialog
  [(showPopup)]="showAvatarChooser"
  [showClose]="showAvatarChooserClose"
  [title]="'Choose Your Avatar'"
  titleIcon="assets/icons/avatar.svg"
>
  <swe-avatar-chooser [(dialog)]="showAvatarChooser"></swe-avatar-chooser>
</swe-dialog>

<swe-dialog
  [(showPopup)]="showThemeChooser"
  [showClose]="true"
  [title]="'Choose Your Theme'"
  titleIcon="assets/icons/theme.svg"
>
  <swe-theme-chooser></swe-theme-chooser>
</swe-dialog>

<swe-log-popup
  *ngIf="showSupportMenu"
  [(showPopup)]="showSupportMenu"
  [(source)]="facade"
  [debugUser]="this.userService.isDebugUser"
></swe-log-popup>

