import { AudioDescriptor, MessageCodes } from "../enums";
import { MessagePopup } from "../types";

export const MESSAGES: MessagePopup[] = [
  {
    code: MessageCodes.HTTP_AUTH_REQUIRED,
    errorName: 'Authentication Required',
    urlSlug: 'authentication-required',
    headText: 'Hold up!',
    messageText: 'Please ask your teacher for help.\n It looks like you are not logged in. Close this window and reopen Istation. If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'fish',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.HTTP_FORBIDDEN,
    errorName: 'Not Authorized',
    urlSlug: 'not-authorized',
    headText: 'Wrong Way!',
    messageText: 'Please ask your teacher for help.\n Oops! How did you get here?',
    hyperLink: 'https://app.istation.com',
    hyperLinkText: '',
    imagePath: 'diamond',
    buttons: [['Link', 'Go Home']],
    buttonImages: ['back'],
    isFullScreen: true,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.HTTP_NOT_FOUND,
    errorName: 'Page Not Found',
    urlSlug: 'page-not-found',
    headText: 'Well, that’s unexpected!',
    messageText: 'Please ask your teacher for help.\n The page you are looking for doesn’t exist anymore or may have moved.',
    hyperLink: 'https://app.istation.com',
    hyperLinkText: '',
    imagePath: 'banana',
    buttons: [['Link', 'Go Home']],
    buttonImages: ['back'],
    isFullScreen: true,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.HTTP_UNEXPECTED_NET_ERROR, 
    errorName: 'Unexpected Network Error',
    urlSlug: 'unexpected-network-error',
    headText: 'Sometimes life gives you lemons!',
    messageText:
      'Please ask your teacher for help. There was a problem loading your content. Close this window and reopen Istation.',
    hyperLink: 'https://app.istation.com',
    hyperLinkText: '',
    imagePath: 'lemon',
    buttons: [['', '']],
    buttonImages: [''],
    isFullScreen: true,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.HTTP_NET_GENERIC,
    errorName: 'Unrecognized Network Error',
    urlSlug: 'unrecognized-network-error',
    headText: 'Yikes!',
    messageText: 'Please ask your teacher for help.\n Something went wrong with the connection to Istation. Close this window and reopen Istation. If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'potato',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.HTTP_SERVER_INTERNAL,
    errorName: 'Internal Server Error',
    urlSlug: 'internal-server-error',
    headText: 'Uh-oh!',
    messageText: 'Please ask your teacher for help.\n Something is not working properly. Close this window and reopen Istation. If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'balloon',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.HTTP_SERVICE_UNAVAIL,
    errorName: 'Service Unavailable',
    urlSlug: 'service-unavailable',
    headText: 'Out to lunch!',
    messageText: 'Please ask your teacher for help.\n Looks like this site is taking a lunch break. Try again later. If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'hamburger',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_CONTENT_LOAD,
    errorName: 'Error loading APP content',
    urlSlug: 'error-loading-app-content',
    headText: 'Where’s my cheese?',
    messageText: 'Please ask your teacher for help.\n There was a problem loading your content. Close this window and reopen Istation.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'cheese',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_STORAGE,
    errorName: 'App Storage Warning',
    urlSlug: 'app-storage-warning',
    headText: 'Woah there!',
    messageText: 'Please ask your teacher for help.\n It looks like your storage space is running low. Consider clearing some storage space on this device.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'truck',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_AUDIO_DEV,
    errorName: 'App Audio Device Failure',
    urlSlug: 'app-audio-device-failure',
    headText: 'It sure is quiet here!',
    messageText: 'Please ask your teacher for help.\n The microphone or headset on this device is missing or not configured. If you’re using headphones, try plugging them in and restarting Istation.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'guitar',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_NETWORK,
    errorName: 'Network Error',
    urlSlug: 'network-error',
    headText: 'Whoops! We’re disconnected!',
    messageText: 'Please ask your teacher for help.\n Istation couldn’t reach its servers. Make sure you are connected to the internet and try again.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'beaver',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_RES,
    errorName: 'Resolution Warning',
    urlSlug: 'resolution-warning',
    headText: 'This doesn’t quite fit!',
    messageText: 'Please ask your teacher for help.\n Your device’s resolution does not meet our suggested requirements. While Istation will operate normally, we recommend a resolution of at least 1024 × 768 for the best experience.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'donut',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_WARN_REFRESH,
    errorName: 'Refresh Warning',
    urlSlug: 'refresh-warning',
    headText: 'Are you sure?',
    messageText: 'Please ask your teacher for help.\n If you reload the site, any changes you’ve made may be not be saved.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'lightning',
    buttons: [['OK', 'Refresh'], ['Cancel', 'Reload']],
    buttonImages: ['refresh', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_MULTI_INSTANCE,
    errorName: 'Multiple Instances Running Warning',
    urlSlug: 'multiple-instances-running-warning',
    headText: 'Too many cooks in the kitchen!',
    messageText: 'Please ask your teacher for help.\n You already have Istation open in another tab. Please close this tab and return to the other tab.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'pizza',
    buttons: [['', '']],
    buttonImages: [''],
    isFullScreen: true,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_COMPAT,
    errorName: 'Compatibility Message',
    urlSlug: 'compatibility-message',
    headText: 'This doesn’t look right!',
    messageText: 'Please ask your teacher for help.\n This platform isn’t currently supported. To view a list of supported platforms and devices, please visit our support page here: <link>.',
    hyperLink: 'https://www.istation.com/Support/IstationBrowser',
    hyperLinkText: 'Istation in the Browser',
    imagePath: 'dinosaur',
    buttons: [['', '']],
    buttonImages: [''],
    isFullScreen: true,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_FATAL_SEQUENCE,
    errorName: 'Fatal Error (App Sequence Error)',
    urlSlug: 'fatal-error-app-sequence-error',
    headText: 'Something strange is happening!',
    messageText: 'Please ask your teacher for help.\n Istation has run into a problem and needs to restart. Close this window and reopen Istation. If this problem persists, <link>.',
    hyperLink: 'https://www.istation.com/Contact',
    hyperLinkText: 'contact Support',
    imagePath: 'butterfly',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_OFFLINE,
    errorName: 'Running Offline',
    urlSlug: 'running-offline',
    headText: 'It looks like you’re offline!',
    messageText: 'You will only be able to visit games and lessons you have viewed in a previous session.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'cactus',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.APP_LOGOUT_CONFIRM,
    errorName: 'Confirm Logout',
    urlSlug: 'confirm-logout',
    headText: 'See you later!',
    messageText: 'Are you sure you want to log out?',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'gator',
    buttons:[['Trigger_LogOut', 'Yes'], ['Cancel', 'No']],
    buttonImages: ['check', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_CLOSE_CONFIRM,
    errorName: 'Confirm Closing Tab/Window While Logged In',
    urlSlug: 'confirm-closing-tab',
    headText: 'Fare-thee-well!',
    messageText: 'Closing this window or tab will log you out of Istation. Any unsaved work may be lost!',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'crown',
    buttons: [['OK', 'OK '], ['Cancel', 'Cancel']],
    buttonImages: ['check', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_IDLE,
    errorName: 'Idle Message',
    urlSlug: 'idle-message',
    headText: 'Are you still there?',
    messageText: 'If you’re finished using Istation, you can log out. If not, we’ll be here when you’re ready to continue!',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'popcorn',
    buttons: [['OK', 'I’m here'], ['Trigger_LogOut', 'Log out']],
    buttonImages: ['check', ''],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_NAV_ORF,
    errorName: 'ORF Confirmation',
    urlSlug: 'orf-confirmation',
    headText: 'Start ORF',
    messageText: 'You are about to open Oral Reading Fluency in your web browser. This will log you out of the Istation website.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'rocket',
    buttons: [['OK', 'Go '], ['Cancel', 'Cancel']],
    buttonImages: ['new-tab', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_NAV_RAN,
    errorName: 'RAN Confirmation',
    urlSlug: 'ran-confirmation',
    headText: 'Start RAN',
    messageText: 'You are about to open Rapid Automatized Naming in your web browser. This will log you out of the Istation website.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'rocket',
    buttons: [['OK', 'Go '], ['Cancel', 'Cancel']],
    buttonImages: ['new-tab', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_NAV_ORF_ES,
    errorName: 'ORF Confirmation (Spanish)',
    urlSlug: 'orf-confirmation-spanish',
    headText: 'Abrir ORF',
    messageText: 'Estás a punto de iniciar la aplicación de Fluidez en la Lectura Oral (ORF) en tu navegador web. Esto cerrará la página de Istation.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'rocket',
    buttons: [['OK', 'OK '], ['Cancel', 'Cancelar']],
    buttonImages: ['new-tab', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.APP_NAV_RAN_ES,
    errorName: 'RAN Confirmation (Spanish)',
    urlSlug: 'ran-confirmation-spanish',
    headText: 'Abrir RAN',
    messageText: 'Estás a punto de iniciar la aplicación de Denominación Rápida Automatizada (RAN) en tu navegador web. Esto cerrará la página de Istation.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'rocket',
    buttons: [['OK', 'OK '], ['Cancel', 'Cancelar']],
    buttonImages: ['new-tab', 'cancel-x'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_QUESTION
  },
  {
    code: MessageCodes.RUNTIME_LOGIN_ERROR,
    errorName: 'Runtime Login Error',
    urlSlug: 'runtime-login-error',
    headText: 'Oops, time to reload!',
    messageText: 'There seems to be a problem! Please click Reload to fix the problem or ask your teacher for help.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'parrot',
    buttons: [['Refresh', 'Reload']],
    buttonImages: ['refresh'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.NO_CLASS,
    errorName: 'No Class Assigned',
    urlSlug: 'no-class-assigned',
    headText: 'Where’s your hall pass?',
    messageText: 'Please ask your teacher for help.\n It looks like you are not assigned to a class. Once you have been assigned to a class, click Reload.',
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'rocket',
    buttons: [['Refresh', 'Reload']],
    buttonImages: ['refresh'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  },
  {
    code: MessageCodes.ACTIVITY_BROWSER_NAV,
    errorName: 'Browser Navigation in Activity',
    urlSlug: 'browser-navigation-in-activity',
    headText: 'Uh-oh!',
    messageText: "Looks like you can't go back! Click OK to stay in your activity or ask your teacher for help.",
    hyperLink: '',
    hyperLinkText: '',
    imagePath: 'blowfish',
    buttons: [['OK', 'OK']],
    buttonImages: ['check'],
    isFullScreen: false,
    audioDesc: AudioDescriptor.SFX_POPUP_ERROR
  }
];