import { Injectable } from "@angular/core";
import Queries from "@swe/data/queries/amira-assignment-api-queries";
import Samples from "@swe/data/samples/amira-assignment-sample";
import { AmiraAssignmentInput, AmiraAssignmentResponse } from "../types";
import { Apollo, ApolloBase } from "apollo-angular";

@Injectable({
    providedIn: 'root',
})
export class AmiraAssignmentService {
    public apollo: ApolloBase;
    
    constructor(private apolloProvider: Apollo) {
        this.apollo = apolloProvider.use('amiraAssignment');
    }
    
    async getAllAssignments(input: AmiraAssignmentInput): Promise<AmiraAssignmentResponse[]> {
        //return Samples.AmiraAllAssignmentSample; // temporarily returning sample data until api is online
        return this.apollo.query<{ getAllAssignments: AmiraAssignmentResponse[] }>({
            query: Queries.ALL_ASSIGNMENT_QUERY,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then(result => result.data.getAllAssignments);
    }

    async getActiveAssignments(input: AmiraAssignmentInput): Promise<AmiraAssignmentResponse[]> {
        //return Samples.AmiraActiveAssignmentSample; // temporarily returning sample data until api is online
        return this.apollo.query<{ studentActiveAssignments: AmiraAssignmentResponse[] }>({
            query: Queries.ACTIVE_ASSIGNMENT_QUERY,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            return result.data.studentActiveAssignments ?? []
        });
    }

    async hasActiveAssignments(input: AmiraAssignmentInput): Promise<boolean> {
        return await this.getActiveAssignments(input).then(assignments => assignments.length > 0);
    }

    async getNextAssignment(input: AmiraAssignmentInput): Promise<AmiraAssignmentResponse[]> {
        return Samples.AmiraStoryAssignmentSample; // temporarily returning sample data until api is online
        return this.apollo.query<{ studentActiveAssignments: AmiraAssignmentResponse[] }>({
            query: Queries.ACTIVE_ASSIGNMENT_QUERY,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            return result.data.studentActiveAssignments ?? [];
        });
    }
}