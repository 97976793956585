import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { configureModule } from '../../classes/runtime/config';
import {
  EmscriptenIstationModule,
  EmscriptenModuleDecorator,
  CacheBustingManifest,
} from '../../types';
import {
  CompatibilityService,
  ConfigurationService,
  FeatureFlagService,
  UrlService,
  UserService,
  WasmService,
} from '../../services';
import manifest from '../../data/wasm-manifest.json';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import { mergeMap, filter, first, debounceTime } from 'rxjs/operators';
import { openDebugConsoleEventName } from '../../app.component';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';

const noopModuleDecorator = (mod: EmscriptenIstationModule) => mod;
const wasmCacheBusting = <CacheBustingManifest>manifest;

@Component({
  selector: 'swe-wasm',
  templateUrl: './wasm.component.html',
  styleUrls: ['./wasm.component.scss'],
})
export class WasmComponent<
  M extends EmscriptenIstationModule = EmscriptenIstationModule
> implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('runtimeCanvas') runtimeCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('runtimeContainer') runtimeContainer!: ElementRef<HTMLCanvasElement>;

  public showFullscreenButton = true;
  public showOrientationOverlay = false;

  protected moduleDecorator: EmscriptenModuleDecorator<M>;

  private resolvedModule!: M;
  private moduleExportName = 'ISApp';
  private wasmJavaScriptLoader = 'isapp.js';
  private subscriptions = new Subscription();
  private debugConsoleTouchCount = 0;

  /* Injected Services */
  public wasmService: WasmService = inject(WasmService);
  public compatibilityService: CompatibilityService = inject(CompatibilityService);
  private document: Document = inject(DOCUMENT);
  private window: Window = inject(WINDOW);
  private httpClient: HttpClient = inject(HttpClient);
  private featureFlagService: FeatureFlagService = inject(FeatureFlagService);
  private userService: UserService = inject(UserService);
  private urlService: UrlService = inject(UrlService);
  private configService: ConfigurationService = inject(ConfigurationService);

  constructor() {
    this.moduleDecorator = configureModule;
  }

  get module(): M {
    return this.resolvedModule;
  }

  ngOnInit(): void {
    // look in the search location for ?Debug
    const queryString = this.window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hasDebug = [...urlParams.entries()].some(([k]) => k.toLowerCase() === "debug");
    hasDebug && this.wasmService.showDebugPanelBehavior.next(true);
    this.showFullscreenButton = !this.compatibilityService.isIpad;
  }

  ngAfterViewInit(): void {
    const {canLaunchWasm, hasUserState} = this.wasmService;
    this.subscriptions.add(
      combineLatest([canLaunchWasm, hasUserState])
        .pipe(filter(([canLaunch, hasUser]) => canLaunch && hasUser && !this.module))
        .subscribe(() => {
          this.resolveModule();
        })
    );
    this.subscriptions.add(fromEvent(this.runtimeCanvas.nativeElement, "touchstart").subscribe(() => 
        this.window.dispatchEvent(new Event('click'))
    ));

    // Check if we should show or hide the orientation overlay on orientation change events.
    // Use debounceTime() to avoid getting orientation and width values that are out of sync.
    this.subscriptions.add(
      fromEvent(this.window.screen.orientation ?? this.compatibilityService.landscapeMediaQueryList, 'change')
        .pipe(debounceTime(100))
        .subscribe(() => this.checkRotation())
    );

    // Everytime the WASM is displayed, tigger an event when the next scene is started.
    this.subscriptions.add(
      this.wasmService.showWasmBehavior
        .pipe(
          filter(e => e)
        )
        .subscribe(
          () => this.wasmService.sceneStarted
            .pipe(
              first(e => !!e)
            )
            .subscribe(() => this.checkRotation())
        )
    );

    if (
      this.featureFlagService.isFlagEnabled('debugTools') 
      || this.userService.isDebugUser
    )
      this.subscriptions.add(fromEvent(this.runtimeContainer.nativeElement, 'touchend').subscribe(event => this.detectOpenDebugConsole(event)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleFullScreen(): void {
    if (this.window._isapp_module) {
      this.window._isapp_module.requestFullscreen(false, false);
      this.window._isapp_module.html5_ui_onFullscreenChange(true);
    }
  }

  /**
   * Check Rotation
   * 
   * Behavior to check and update the view to show the orientation overlay when
   * the screen requires rotation and the runtime is visible.
   */
  checkRotation(): void {
    if (
      this.featureFlagService.isFlagEnabled("rotateOverlay") 
      && this.wasmService.showWasmBehavior.value
    ) {
      this.showOrientationOverlay = this.compatibilityService.shouldRotate;
      if (this.window._isapp_module && this.showOrientationOverlay) {
        this.window._isapp_module.PauseRuntime(true);
      }
    }
  }

  /**
   * Loads a JavaScript script async into the page
   *
   * @param id the HTML id for the script
   * @param url the URL to the generated JavaScript loader
   */
  loadScript(id: string, url: string): Promise<void> {
    let script = <HTMLScriptElement>this.document.getElementById(id);
    if (script) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      script = this.document.createElement('script');
      this.document.body.appendChild(script);
      script.onload = () => resolve();
      script.onerror = (ev: Event | string) => reject(ev);
      script.id = id;
      script.async = true;
      script.src = url;
    });
  }

  protected resolveModule(): void {
    const jsVersion = wasmCacheBusting[this.wasmJavaScriptLoader]
      ? `?v=${wasmCacheBusting[this.wasmJavaScriptLoader]}`
      : "";
    this.loadScript(
      this.moduleExportName,
      `${this.configService.config.runtimeWasmPath}${this.wasmJavaScriptLoader}${jsVersion}`
    )
      .then(() => {
        const module = <M>({
          locateFile: (file: string) => {
            const fileVersion = wasmCacheBusting[file] ? `?v=${wasmCacheBusting[file]}` : "";
            return `${this.configService.config.runtimeWasmPath}${file}${fileVersion}`;
          },
        } as unknown);
        const moduleDecorator: EmscriptenModuleDecorator<M> =
          this.moduleDecorator || noopModuleDecorator;
        moduleDecorator(module, this.urlService, this.configService);
        this.window['_isapp_module'] = module;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.resolvedModule = (this.window as any)[this.moduleExportName](module);
      });
  }

  /**
   * If the area surrounding the Wasm runtime canvas gets more than 2 touches in 1 second,
   * then open the debug console
   */
  detectOpenDebugConsole(event: Event): void {
    // Ignore the touch event if it's not in the target area
    if (!(event.target instanceof Element) || event.target.tagName !== 'DIV')
      return;
    
    if (this.debugConsoleTouchCount++ === 0) {
      setTimeout(() => {
        if (this.debugConsoleTouchCount > 2)
          this.window.dispatchEvent(new CustomEvent(openDebugConsoleEventName));
        
        this.debugConsoleTouchCount = 0;
      }, 1000);
    }
  }

  /**
   * @deprecated
   */
  handleWasmLaunch(): void {
    const module = <M & { onLaunchButtonClicked: () => void }>this.module;
    module.onLaunchButtonClicked.call(this);
  }

  /**
   * Instantiate WASM with dependencies
   * 
   * @deprecated
   * @param url 
   * @param imports 
   * @returns Promise<WebAssembly.WebAssemblyInstantiatedSource>
   */
  private instantiateWasm(
    url: string,
    imports?: WebAssembly.Imports
  ): Promise<WebAssembly.WebAssemblyInstantiatedSource> {
    return this.httpClient
      .get(url, { responseType: 'arraybuffer' })
      .pipe(mergeMap((bytes) => WebAssembly.instantiate(bytes, imports)))
      .toPromise();
  }
}
