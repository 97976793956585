import subjectsInfo from '../data/subjects-info';
import SubjectDescriptions from '../data/subject-descriptions';
import SubjectThumbnails from '../data/subject-thumbnails';
import SubjectSubheadings from '../data/subject-subheadings';
import IsipKbFolderNames from '../data/isip-kb-folders';
import { GradeBands, Grades } from '../enums';
import {
  DictionaryDateValue,
  DictionaryISIPValue,
  DictionaryScoresValue,
  InvokeResponseDateResult,
  InvokeResponseResult,
  InvokeResponseScoreResults,
  IsipMonthInfo,
  IsipSubtest,
  StudentSubject,
  StudentSubjectIsip,
} from '../types';
import { SubjectCard } from '../classes';

export class SubjectFactory {
  /**
   * Given the name of a subject, returns a StudentSubject object containing
   * the information for the subject. If the given subject is not found, this
   * returns the "Invalid Subject".
   *
   * @param name Name of the subject to get information for
   * @param isNeeded Is the subject needed?
   * @param band Grade band for the user
   * @returns a StudentSubject containing information for the given subject,
   */
  static getSubject(
    name: string,
    isNeeded: boolean = false,
    band: GradeBands = GradeBands.BAND_1
  ): StudentSubject {
    if (!(name in subjectsInfo)) {
      // console.log('Could not find data for (invalid) subject: ' + name);
      return structuredClone(subjectsInfo['Invalid']);
    }
    const subject = structuredClone(subjectsInfo[
      name as keyof typeof subjectsInfo
    ]) as StudentSubject;
    subject.description = this.getDescription(name, band);
    subject.image = this.getThumbnail(name, band);
    subject.subheading = this.getSubheading(name, band);
    subject.isNeeded = isNeeded;

    return subject;
  }

  /**
   * Given the name of a subject and a dictionary containing user information
   * for that subject. returns a StudentSubjectIsip object containg
   * information for the subject (including the user data). If the given
   * subject is not found, this returns the "Invalid ISIP".
   *
   * @param name string with the name of the subject to get information for
   * @param dict    a dictionary with user data relating to the subject from
   *                the user's KB
   * @returns a StudentSubjectIsip containting information for the given
   *          subject
   */
  static getIsip(
    name: string,
    dict: DictionaryISIPValue,
    band: GradeBands = GradeBands.BAND_1,
    grade: Grades = Grades.Prekindergarten,
  ): StudentSubjectIsip {
    const key = name + 'Isip';
    if (!(key in subjectsInfo)) {
      // console.log('Could not find data for (invalid) ISIP: ' + name);
      return structuredClone(subjectsInfo['InvalidIsip'] as StudentSubjectIsip);
    }
    const isip = structuredClone(subjectsInfo[
      key as keyof typeof subjectsInfo
    ] as StudentSubjectIsip);

    isip.kbIsipFolder = SubjectFactory.getIsipKbFolderName(name, grade);
    isip.isNeeded = this.parseBoolean(dict.IsNeeded);
    isip.startingPeriod = parseInt(dict.StartingPeriod.value, 10);
    isip.period = parseInt(dict.Period.value, 10);
    isip.lastCompleted = this.parseDate(dict.lastCompleted);
    isip.isRunningAdhoc = this.parseBoolean(dict.isRunningAdhoc);

    const subtests = this.parseSubtests(dict.Scores.value);
    isip.subtests = subtests[0];
    isip.totalScores = subtests[1];
    isip.goals = subtests[2];

    isip.description = this.getDescription(key, band);
    isip.image = this.getThumbnail(key, band);
    isip.subheading = this.getSubheading(key, band);

    return isip;
  }

  /**
   * Get the description for the given subject for the given gradeband.
   *
   * @param name name of the subject
   * @param band the grade band
   * @returns a string containing the description
   */
  static getDescription(name: string, band: GradeBands): string {
    return this.getData(SubjectDescriptions, name, band);
  }

  /**
   * Get the thumbnail URL for the given subject for the given gradeband.
   *
   * @param name name of the subject
   * @param band the grade band
   * @returns a string containing the thumbnail URL
   */
  static getThumbnail(name: string, band: GradeBands): string {
    return this.getData(SubjectThumbnails, name, band);
  }

  static getSubheading(name: string, band: GradeBands): string {
    return this.getData(SubjectSubheadings, name, band);
  }

  /**
   * Get the data for the given subject for the given gradeband.
   *
   * @param source the data source to pull from
   * @param name name of the subject
   * @param band the grade band
   * @returns a string containing the description
   */
  private static getData(source: {[key: string]: {[index: number]: string}}, key: string, band: GradeBands): string {
    if (!source) {
      // console.log("SubjectFactory->getData > invalid source");
      return '';
    }
    if (!(key in source)) {
      // console.log('SubjectFactory->getData > cannot find ' + source.constructor.name + ' -> ' + key);
      return '';
    }
    const data = source[key as keyof typeof source];
    if (!(band in data)) {
      // console.log('SubjectFactory->getData > cannot find ' + source.name + ' -> ' + key + ' -> ' + band);
      return '';
    }
    const str = data[band as keyof typeof data];
    // console.log('SubjectFactory->getData > found: ' + str);
    return str;
  }

  /**
   * Parses a given dictionary containg subtests and scores for those subtests.
   *
   * TODO: complete this
   *
   * @param scoresValue a dictionary containing scores for the subtests
   * @returns TODO
   */
  static parseSubtests(
    scoresValue: DictionaryScoresValue | string
  ): [Array<IsipSubtest>, IsipMonthInfo[], IsipMonthInfo[] | undefined] {
    let subtests: IsipSubtest[] = []; //ordered array of subtest skills based on
    let overall: IsipMonthInfo[] = [];
    let goals;

    if(scoresValue && typeof scoresValue !== 'string') {
      // key will be one of ['Goals', 'Overall', 'Skills']
      for (const [key, value] of Object.entries(scoresValue)) {
        const scoresArr = new Array<IsipMonthInfo>();
        if(value && value.value){
          for (const [scoreKey, scoreValue] of Object.entries(value.value)) {
            scoresArr.push({
              period: parseInt(scoreKey, 10),
              value: parseFloat(scoreValue.value),
            });
          }
        }
        if (key === 'Overall') {
          overall = scoresArr;
        } else if (key === 'Goals') {
          goals = scoresArr;
        } else {
          subtests = this.getOrderedSubskills(value);
        }
      }
    }

    return [subtests, overall, goals];
  }

  /**
   * Parses the skills dictionary to get an an array of ordered skill names
   * @param skillsDict the dictionary object from skills key when parsing subtests
   * @returns Array of skill names ordered from least(0) to greatest(n)
   */
  static getOrderedSubskills(skillsDict: InvokeResponseScoreResults | Record<string, never>): Array<IsipSubtest> {
    const values = skillsDict.value;
    const skillsList: IsipSubtest[] = values ? Object.values(values)
      .reduce<IsipSubtest[]>((acc, v) => {if (v && v.value) acc.push({ name: v.value }); return acc;}, [])
      : [];
    return skillsList;
  }

  /**
   * Parses a given dictionary and returns a date.
   *
   * @param dateValue a dictionary containing a date
   * @returns a Date if the input was valid, undefined otherwise
   */
  static parseDate({
    type: dictType,
    value,
  }: InvokeResponseDateResult): Date | undefined {
    if (dictType === 'vt_time' || typeof value !== 'string') {
      const { year, month, day, hour, minute, second } =
        value as DictionaryDateValue;
      const calcdYear = year + 1900; //joe date year starts at 1900.
      return new Date(calcdYear, month, day, hour, minute, second);
    }
    if (dictType !== 'vt_empty') console.warn(`We're trying to parse date on a non date :> ${dictType}`);
    return undefined;
  }

  /**
   * Parses boolean from dictionary object created by jovascript
   * @param boolValue the object that represents a boolean from jovascript
   * @returns returns true if .value string was 'true', false if not.
   */
  static parseBoolean(boolValue: InvokeResponseResult): boolean {
    return boolValue.value === 'true';
  }

  /**
   * Sorts an array of StudentSubject and StudentSubjectIsip objects by their
   * sortPriority in ascending order.
   *
   * @param subjects the array of StudentSubject and StudentSubjectIsip
   *                 objects to sort
   * @returns the sorted array of StudentSubject and StudentSubjectIsip
   *          objects
   */
  static sortSubjectsByPriority(
    subjects: Array<StudentSubject | StudentSubjectIsip | SubjectCard>
  ): (StudentSubject | StudentSubjectIsip)[] {
    return subjects.sort(this.compareSortPriority);
  }

  /**
   * Compares the sortPriority property of two student subjects.
   *
   * @param a the first StudentSubject object to be compared
   * @param b the second StudentSubject object to be compared
   * @returns -1, 0, or 1 as a is less than, equal to, or greater than the second
   */
  static compareSortPriority(a: StudentSubject, b: StudentSubject): number {
    if (a.sortPriority < b.sortPriority) {
      return -1;
    }
    if (a.sortPriority > b.sortPriority) {
      return 1;
    }
    return 0;
  }

  /**
   * Remove duplicate StudentSubject objects from an array of StudentSubject
   * objects. A StudentSubject is considered a duplicate if it has the same
   * name as another.
   *
   * @param subjects array of StudentSubject objects
   * @returns an array of StudentSubjects objects with no duplicates
   */
  static makeSubjectsUnique(subjects: StudentSubject[]): StudentSubject[] {
    const uniqueSubjects: StudentSubject[] = [];
    if (subjects == null || subjects.length == 0) {
      return uniqueSubjects;
    }

    const names: string[] = [];
    subjects.forEach((subject) => {
      if (!names.includes(subject.name)) {
        uniqueSubjects.push(subject);
        names.push(subject.name);
      }
    });

    return uniqueSubjects;
  }

  static updateAllSubjectsDetails(
    subjects: StudentSubject[],
    band: GradeBands = GradeBands.BAND_1
  ): void {
    if (subjects == null || subjects.length == 0) {
      // console.log("update is no go");
      return;
    }
    subjects.forEach((subject) => {
      this.updateSubjectDetails(subject, band);
    });
  }

  static updateSubjectCards(
    subjects: SubjectCard[],
    band: GradeBands = GradeBands.BAND_1
  ): SubjectCard[] {
    return subjects.map((subject) => {
      const {
        _name: subjectName,
        isip: { name: isipName },
      } = subject;
      if (isipName) {
        Object.assign(subject.isip, {
          description: this.getDescription(isipName, band),
          image: this.getThumbnail(isipName, band),
          subheading: this.getSubheading(isipName, band),
        });
      }
      return Object.assign(subject, {
        description: this.getDescription(subjectName, band),
        image: this.getThumbnail(subjectName, band),
        subheading: this.getSubheading(subjectName, band),
      });
    });
  }

  static updateSubjectDetails(
    subject: StudentSubject,
    band: GradeBands = GradeBands.BAND_1
  ) {
    if (subject == null) {
      return;
    }
    subject.description = this.getDescription(subject._name, band);
    subject.image = this.getThumbnail(subject._name, band);
    subject.subheading = this.getSubheading(subject._name, band);
  }

  static getSubjectById(
    id: string,
    subjects: StudentSubject[]
  ): StudentSubject | undefined {
    return subjects.find((subject) => {
      return subject._name === id;
    });
  }

  static getRelatedSubject(
    subject: StudentSubject,
    haystack: StudentSubject[]
  ): StudentSubject | undefined {
    if (!subject.related) {
      return undefined;
    }
    const related = this.getSubjectById(subject.related, haystack);
    return related;
  }

  /**
   * Gets the appropriate KB folder/section name for the given subject + grade.
   * @param name 
   * @param grade 
   * @returns 
   */
  static getIsipKbFolderName(name: string, grade: number): string {
    const prefix = grade >=4 ? 'Advanced' : 'Early';
    return IsipKbFolderNames[(prefix + name) as keyof typeof IsipKbFolderNames] 
            || IsipKbFolderNames[name as keyof typeof IsipKbFolderNames];
  }
}
