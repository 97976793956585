import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import {
  AmiraSRSService,
  ConfigDataService,
  ConfigurationService,
  FeatureFlagService,
  InactivityService,
  LoaderService,
  UserService,
  WasmService,
} from './';
import activityActions from '@swe/data/activity-actions';
import paths from '@swe/data/routing-paths';
import { AmiraAssignmentActivityTypes, AmiraAssignmentDisplayStatus, AmiraAssignmentStatus, AmiraAssignmentTypes } from '@swe/enums';
import { AssignmentMeta, SubjectCard } from '@swe/classes';
import { AmiraSRSMutateActivityInput } from '@swe/types/amira-srs-api-types';
import { isAssessmentTypeAssignment } from '../shared/utilities/amira-assignment-utils';

@Injectable({
  providedIn: 'root',
})
export class ActivityLauncherService {
  private configService = inject(ConfigurationService);
  private configDataService = inject(ConfigDataService);
  private flags = inject(FeatureFlagService);
  private inactivityService =  inject(InactivityService);
  private router = inject(Router);
  private userService = inject(UserService);
  private loaderService = inject(LoaderService);
  private wasmService = inject(WasmService);
  private amiraSRSService = inject(AmiraSRSService);
  
  private document = inject(DOCUMENT);
  private window = inject(WINDOW);
  
  private readonly flagLookup: {[key: string]: (grade: number) => string} = {
    'Lectura': (grade: number) => this.determineLecturaWebIsipFlag(grade),
    'Matemáticas': (grade: number) => this.determineMatematicasWebIsipFlag(grade),
    'Math': (grade: number) => this.determineMathWebIsipFlag(grade),
    'Reading': (grade: number) => this.determineReadingWebIsipFlag(grade),
  }

  launchActivity(link: string[]) {
    this.inactivityService.recordActivity();

    // console.log('>> Launching activity', link);
    if (!link?.length) {
      // console.warn('No link provided')
      return;
    }

    switch (link[0]) {
      case 'wasm':
        this.launchWasmActivity(link.slice(1));
        break;
      case 'wasmr':
        this.launchWasmRange(link.slice(1));
        break;
      case 'menu':
        this.goToRoute(link.slice(1));
        break;
      case 'verbals':
        this.goToVerbal(link);
        break;
      case 'isip':
        this.goToIsip(link[1] || '');
        break;
      case 'webIsip':
        this.goToWebIsip(link.slice(1));
        break;
      case 'isip-skip-flow':
        this.goToIsip(link[1]);
        break;
      case 'amira':
        this.goToAmiraTutor();
        break;
      default:
        this.goToLinearPath(link[0]);
    }
  }

  private validateAmiraAssignment(assignmentCard: AssignmentMeta): boolean {
    if (!assignmentCard || !assignmentCard.launchArgs) {
      console.warn("Unable to Launch Assignment: No assignment data");
      return false;
    }

    if (
      !assignmentCard.assignmentId ||
      !assignmentCard.assignmentType
    ) {
      console.warn("Unable to Launch Assignment: No assignment ID or type");
      return false;
    }

    if (
      !isAssessmentTypeAssignment(assignmentCard.assignmentType) &&
      !assignmentCard.activityType
    ) {
      // Non-assessment assignmentTypes need to have a provided activityType
      console.warn("Unable to Launch Assignment: No activity type");
      return false;
    }

    return true;
  }

  async launchAmiraAssignment(subjectCard: SubjectCard): Promise<void> {
    const assignmentCard: AssignmentMeta = subjectCard.getAssignment();
    if (!this.validateAmiraAssignment(assignmentCard)) {
      return;
    }

    if (isAssessmentTypeAssignment(assignmentCard.assignmentType)) {
      this.goToAmiraAssessment(
        assignmentCard.assignmentId,
        assignmentCard.assignmentType,
      );
      return;
    }

    let srsActivityId;
    if (
      assignmentCard.activityType === AmiraAssignmentActivityTypes.MICROLESSON ||
      assignmentCard.activityType === AmiraAssignmentActivityTypes.LEGACYISTATION
    ) {
      if (!assignmentCard.storyId) {
        console.warn("Unable to Launch Assignment: No story ID");
        return;
      }

      srsActivityId = await this.createSRSActivity(
        assignmentCard.assignmentId,
        assignmentCard.activityType,
        assignmentCard.storyId,
        assignmentCard.contentTags
      );

      if (!srsActivityId) {
        console.warn("Unable to Launch Assignment: No SRS activity ID");
        return;
      }
    }

    switch (assignmentCard.activityType) {
      case AmiraAssignmentActivityTypes.MICROLESSON:
        if (assignmentCard.launchArgs?.activityUuid && srsActivityId) {
            this.goToMicrolesson(
              assignmentCard.assignmentId,
              assignmentCard.assignmentType,
              assignmentCard.launchArgs.activityUuid, 
              assignmentCard.launchArgs.skill,
              srsActivityId,
            );
            return;
          }
        break;
      case AmiraAssignmentActivityTypes.LEGACYISTATION:
        if (
          assignmentCard.launchArgs?.startingJumpPath &&
          assignmentCard.launchArgs?.endingJumpPath &&
          assignmentCard.launchArgs?.stopBefore &&
          srsActivityId
        ) {
          this.launchWasmRangeAsAmiraAssignment([
            assignmentCard.launchArgs.startingJumpPath,
            assignmentCard.launchArgs.endingJumpPath,
            assignmentCard.launchArgs.stopBefore.toString(),
            '/home',
            srsActivityId
          ]).then(() => this.loaderService.hideLoader());
        }
        return;
        break;
      case AmiraAssignmentActivityTypes.STORY:
        if (assignmentCard.launchArgs?.storyId) { 
          this.goToAmiraStory(
            assignmentCard.assignmentId,
            assignmentCard.assignmentType,
            assignmentCard.launchArgs.storyId);
        }
        return;
        break;
      case AmiraAssignmentActivityTypes.SKILL:
        if (assignmentCard.launchArgs?.skill) {
          assignmentCard.assignmentId,
          assignmentCard.assignmentType,
          this.goToAmiraSkill(
            assignmentCard.assignmentId,
            assignmentCard.assignmentType,
            assignmentCard.launchArgs.skill
          );
        }
        return;
        break;
      default:
        console.warn("Launching Amira Tutor: Unknown assignment type");
        this.goToAmiraTutor();
        break;
    }

    // if we've gotten this far, something went wrong with the launch
    console.warn("Unable to Launch Assignment: Invalid launch data");
  }

  launchWasmActivity(link: string[]) {
    const activity = link[0];
    const returnPath = link[1] || '';
    this.wasmService.executeActivity(activity, returnPath);
  }

  launchWasmRange(link: string []) {
    const start = link[0];
    const end   = link[1];
    const stopBefore = link[2] === 'true';
    const returnPath = link[3] || '';
    this.wasmService.executeActivityRange(start, end, stopBefore, returnPath);
  }
  
  launchWasmRangeAsAmiraAssignment(link: string []) {
    const start = link[0];
    const end   = link[1];
    const stopBefore = link[2] === 'true';
    const returnPath = link[3] || '';
    const srsActivityId = link[4] || '';
    return this.wasmService.executeActivityRangeAsAmiraAssignment(srsActivityId, start, end, stopBefore, returnPath);
  }

  private goToVerbal(link: string[]): void {
    const str = link[1]?.toLowerCase()
    if (!str) {
      return;
    }

    const returnPath = this.window.location.origin;
    this.goToIsipUrl(str, returnPath);
  }

  private goToWebIsip(link: string[]): void {
    if (link.length != 2) {
      return;
    }
    const returnPath = this.window.location.origin + '/' + link[0].toLowerCase();
    const shortcut = link[1].toLowerCase();
    this.goToIsipUrl(shortcut, returnPath);
  }

  goToIsipUrl(shortcut: string, returnPath: string): void {
    const url = new URL(this.configService.config.webIsipURL);
    const params = new URLSearchParams({
      shortcut: shortcut,
      returnUrl: returnPath,
    });
    if (this.wasmService.hasEffectiveDate()) {
      const dateOverride = this.wasmService
        .getEffectiveDate()
        .toLocaleDateString('en-US',  { year: 'numeric', month: '2-digit', day: '2-digit' });
      params.set('applicationDateOverride', dateOverride);
    }
    url.search = params.toString();
    // console.log('%c goToIsipUrl => ', 'background:yellow;color:black;', {shortcut}, {returnPath}, {url}, this.window.location.origin);
    this.goToUrl(url);
  }

  goToUrl(url: URL, doUseFullReferrer: boolean = false): void {
    if (doUseFullReferrer) {
      const a = this.document.createElement('a');
      a.style.display = 'none';
      a.setAttribute('href', url.toString());
      a.setAttribute('referrerpolicy', 'unsafe-url')
      this.document.body.appendChild(a);
      a.click();
    } else {
      this.window.location.href = url.href;
    }
  }

  goToRoute(link: string[], query?: Params): void {
    // console.log('going to', {link});
    this.router.navigate(link, {
      queryParams: query,
    });
  }

  goToLinearPath(link: string): void {
    const navTo = link.charAt(0).toUpperCase() + link.slice(1);
    const route = paths[`${navTo}Linear`]
    this.wasmService.executeActivity(route);
  }

  goToAction(subject: string, detail: string): void {
    const navTo = subject.charAt(0).toUpperCase() + subject.slice(1);
    const action = activityActions[`${navTo}${detail}`];
    this.launchActivity(action);
  }
  
  async goToIsip(subject: string): Promise<void> {
    this.goToAction(subject, await this.useWebIsip(subject) ? 'WebIsip' : 'Isip');
  }

  goToModeling(subject: string): void {
    this.goToAction(subject, 'Modeling');
  }

  goToAmiraIsipPilot(
    amiraIsipPilotGuid: string,
  ): void {
    const path = this.configService.config.amiraTutorUrl;
    const url = new URL(path);
    url.search = new URLSearchParams({
      amiraIsipPilotGuid: amiraIsipPilotGuid,
      assignmentType: 'ASSESSMENT',
      as_role: 'student',
      inboxReturnUrl: this.window.location.origin,
      access_token: this.wasmService.accessToken.value,
    }).toString();
    console.log("Launching Amira Isip Pilot", url);
    this.goToUrl(url, true);
  }

  goToAmiraAssessment(
    assignmentId: string,
    assignmentType: AmiraAssignmentTypes,
  ): void {
    const path = this.configService.config.amiraTutorUrl;
    const url = new URL(path);
    url.search = new URLSearchParams({
      assignmentId: assignmentId,
      assignmentType: assignmentType,
      inboxReturnUrl: this.window.location.origin,
      access_token: this.wasmService.accessToken.value,
    }).toString();
    console.log("Launching Amira Assessment", url);
    this.goToUrl(url, true);
  }

  goToAmiraTutor(): void {    
    const path = this.configService.config.amiraTutorUrl;
    const url = new URL(path);
    url.search = new URLSearchParams({
      inboxReturnUrl: this.window.location.origin,
      access_token: this.wasmService.accessToken.value,
    }).toString();
    console.log("Launching Amira Tutor", url);
    this.loaderService.showLoader();
    this.goToUrl(url, true);
  }

  goToAmiraStory(
    assignmentId: string, 
    assignmentType: AmiraAssignmentTypes, 
    storyId: string
  ): void {
    const path = this.configService.config.amiraTutorUrl;
    const url = new URL(path);
    url.search = new URLSearchParams({
      assignmentId: assignmentId,
      assignmentType: assignmentType,
      storyId: storyId,
      inboxReturnUrl: this.window.location.origin,
      access_token: this.wasmService.accessToken.value,
    }).toString();
    console.log("Launching Amira Story", url);
    this.goToUrl(url, true);
  }

  goToAmiraSkill(
    assignmentId: string, 
    assignmentType: AmiraAssignmentTypes, 
    skill: string,
  ): void {
    const path = this.configService.config.amiraTutorUrl;
    const url = new URL(path);
    url.search = new URLSearchParams({
      assignmentId: assignmentId,
      assignmentType: assignmentType,
      skill: skill,
      inboxReturnUrl: this.window.location.origin,
      access_token: this.wasmService.accessToken.value,
    }).toString();
    console.log("Launching Amira Skill", url);
    this.goToUrl(url, true);
  }

  goToMicrolesson(
    assignmentId: string,
    assignmentType: AmiraAssignmentTypes,
    activityUuid: string, 
    skill: string | undefined,
    srsActivityId: string,
  ): void {
    const path = this.configService.config.microlessonsURL + '/inbox/' + activityUuid + '/' + srsActivityId + '/' + assignmentId;
    const url = new URL(path);
    const params: Record<string, string> = {
      assignmentType: assignmentType,
    };
    if (skill) {
      params.skill = skill;
    }
    url.search = new URLSearchParams(params).toString();
    console.log("Launching Microlesson", url);
    this.goToUrl(url, true);
  }

  async createSRSActivity(
    assignmentId: string,
    activityType: AmiraAssignmentActivityTypes | undefined,
    storyId: string,
    tags: string[] | undefined
  ): Promise<string | void> {
    const srsInput: AmiraSRSMutateActivityInput = {
      assignmentId: assignmentId,
      type: activityType ?? AmiraAssignmentActivityTypes.UNKNOWN,
      storyId: storyId,
      tags: tags,
      districtId: this.userService.amiraDistrictId.value.toString(),
      schoolId: this.userService.amiraSchoolId.value.toString(),
      studentId: this.userService.id.toString(),
      displayStatus: AmiraAssignmentDisplayStatus.IN_PROGRESS,
      status: AmiraAssignmentStatus.SCORING_NOT_STARTED,
    }

    return this.amiraSRSService.createActivity(srsInput);
  }

  /**
   * Determines if the subject is available via web isip to the user
   * @returns true if this should use web ISIP
   */
  async useWebIsip(subject: string): Promise<boolean> {
    // This is a feature that only exists in Early Reading. Any future additions to this feature 
    // would be done diretcly in WebISIP, so we don't neeed to check for others
    if (subject.toLowerCase() === 'reading' && this.userService.grade <= 3) {
      const override = await this.configDataService.get('Screeners', 'InstructionsLang');
      if (override && override.toString().toLowerCase() !== 'en') {
        return Promise.resolve(false);
      }
    }
    
    const flag = this.determineWebIsipFlag(subject, this.userService.grade);
    return Promise.resolve(this.flags.isFlagEnabled(flag));
  }

  determineWebIsipFlag(subject: string, grade: number): string {
    if (Object.prototype.hasOwnProperty.call(this.flagLookup, subject)) {
      return this.flagLookup[subject](grade);
    }
    return '';
  }

  private determineLecturaWebIsipFlag(grade: number): string {
    // there's overlap with 2-3 and the other two flags because reasons
    return grade >=  2 && grade <= 3 ? 'enableWebIsipLt23' :
           grade >= -1 && grade <= 2 ? 'enableWebIsipLt' :
           grade >=  4 							 ? 'enableWebIsipLa' :
                                        '';
  }

  private determineMatematicasWebIsipFlag(grade: number): string {
    return grade >= -1 && grade <= 1 ? 'enableWebIsipMatesPk1' :
           grade >=  2 && grade <= 5 ? 'enableWebIsipMates25' :
                                       '';
  }

  private determineMathWebIsipFlag(grade: number): string {
    return grade >= -1 && grade <= 1 ? 'enableWebIsipMathPk1' :
           grade >=  2 && grade <= 8 ? 'enableWebIsipMath28' :
                                        '';
  }

  private determineReadingWebIsipFlag(grade: number): string {
    return grade >= -1 && grade <= 3 ? 'enableWebIsipEr' :
           grade >=  4 							 ? 'enableWebIsipAr' :
                                        '';
  }
}
