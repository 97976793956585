import { EpisodeCard } from "@swe/pages/lesson/classes";
import { AssignmentStatus, DeadlineStatus } from '../enums';

export class AssignmentCard extends EpisodeCard {
  assignmentId = 0; // could be different from id for EpisodeCard/microlesson
  parentSeries!: string;
  siblingCount!: number;
  product!: string;
  skill!: string;
  status!: AssignmentStatus;
  private _dateAvailable!: Date;  // date assignment is available to take
  private _dateCompleted?: Date;  // date assignment was completed
  private _dateDue?: Date;        // date assignment is due (might not have one)
  private _dateStarted?: Date;    // date assignment was started

  constructor(data?: Partial<AssignmentCard>) {
    super();
    return Object.assign(this, data);
  }

  // #region date getters and setters
  set dateAvailable(date: Date) {
    this._dateAvailable = this.removeTimeFromDate(date);
  }
  get dateAvailable(): Date {
    return this._dateAvailable;
  }

  set dateCompleted(date: Date | undefined) {
    this._dateCompleted = date;
  }
  get dateCompleted(): Date | undefined {
    return this._dateCompleted;
  }

  set dateDue(date: Date | undefined) {
    this._dateDue = date ? this.removeTimeFromDate(date) : undefined;
  }
  get dateDue(): Date | undefined {
    return this._dateDue;
  }

  set dateStarted(date: Date | undefined) {
    this._dateStarted = date ? this.removeTimeFromDate(date) : undefined;
  }
  get dateStarted(): Date | undefined {
    return this._dateStarted;
  }
  // #endregion

  get deadlineStatus(): DeadlineStatus {
    if (this.status === AssignmentStatus.COMPLETED) {
      return DeadlineStatus.COMPLETED;
    } else if (!this.dateDue) {
      return DeadlineStatus.NONE;
    }

    const today = this.getCurrentDate();
    const tomorrow = new Date(today);
    const todaysDate = this.convertToDateStamp(today);
    const deadlineDate = this.convertToDateStamp(this.dateDue);
    tomorrow.setDate(today.getDate() + 1);
    const tomorrowsDate = this.convertToDateStamp(tomorrow);

    // console.warn('Assignment Card Class::deadlineStatus', deadlineDate, todaysDate, tomorrowsDate );

    if (todaysDate === deadlineDate) {
      return DeadlineStatus.DUE_TODAY;
    } else if (tomorrowsDate === deadlineDate) {
      return DeadlineStatus.DUE_TOMORROW;
    } else if (todaysDate > deadlineDate) {
      return DeadlineStatus.PAST_DUE;
    }
    return DeadlineStatus.CURRENT;
  }

  get localizedDeadline(): string {
    const dateLocalizer = this.languageCode?.includes("es") ? 'es-MX' : 'en-US';
    return this.deadlineStatus === DeadlineStatus.DUE_TODAY 
      ? 'ASSIGNMENTS.TODAY'
        : this.deadlineStatus === DeadlineStatus.DUE_TOMORROW 
          ? 'ASSIGNMENTS.TOMORROW'
            : this.getFormattedDateDue(dateLocalizer)
  }

  // formats the date in [long-month dd] format
  getFormattedDateDue(lang: string = 'en-US'): string {
    if (!this._dateDue) {
      return "ASSIGNMENTS.DUE_NONE";
    }
    return this._dateDue.toLocaleDateString(
      lang,
      { 
        month: 'long', 
        day: 'numeric',
        timeZone: 'UTC'
      }
    );
  }

  // formats the date in [mm/dd/yyyy] format
  getFormattedDateCompleted(lang: string = 'en-US'): string {
    if (!this._dateCompleted) {
      return ''; // we shouldn't ever get here
    }
    return this._dateCompleted.toLocaleDateString(
      lang,
      { 
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC'
      }
    );
  }

  // formats the data in ISO 8601 compliant YYYYMMDD format
  private convertToDateStamp(dateToParse?: Date): string {
    dateToParse = dateToParse && dateToParse instanceof Date ? dateToParse : new Date();
    return `${
      dateToParse.getFullYear()
    }${
      (dateToParse.getMonth() + 1).toString().padStart(2,'0')
    }${
      dateToParse.getUTCDate().toString().padStart(2,'0')
    }`;
  }

  // gets the current date, or (if available) the debug effective/time-travel date
  private getCurrentDate(): Date {
    const effectiveDate = sessionStorage.getItem('EffectiveDate');
    const date = effectiveDate
      ? new Date(parseInt(effectiveDate, 10))
      : new Date();
    return this.removeTimeFromDate(date);
  }

  // removes hours/minutes/seconds/milliseconds info from date
  private removeTimeFromDate(date: Date): Date {
    date.setUTCHours(0,0,0,0);
    return date;
  }
}
