import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MESSAGES } from '@swe/data/messages';
import { DataSourceType, IstationSubjects } from '@swe/enums';
import { AssignmentRoute } from '@swe/pages/assignments/enums';
import { MessageService } from '@swe/features/message-popup/message.service';
import {
  AssessmentApiService,
  ConfigDataService,
  ContentMetadataService,
  UsageService,
  UserService,
  WasmService,
} from '@swe/services';
import { MessagePopup } from '@swe/types';
import { getIstationSubject } from '@swe/shared/utilities';

@Component({
  standalone: true,
  selector: 'swe-general-debug',
  templateUrl: './general-debug-control.component.html',
  styleUrls: [
    '../debug.component.scss',
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class GeneralDebugControlComponent implements OnInit, AfterViewInit {
  effectiveDate!: string;
  subscriptions = new Subscription();
  executePathForm!: UntypedFormGroup;
  usageForm!: FormGroup;
  errorForm!: UntypedFormGroup;
  availableErrors: Array<Pick<MessagePopup, "code" | "errorName">>;
  apiYear: string | undefined = undefined;
  displayDate =  "";

  readonly DATE_MIN = new Date('1950-01-01');
  readonly DATE_MAX = new Date('2100-12-31');

  readonly executeStash = 'debugLaunchInfo';
  readonly iniPath = '/data/IS.Ini';
  readonly activityPath = '/activity';

  @ViewChild('output') contents!: ElementRef;
  @ViewChild('saveButton') save!: ElementRef;
  @ViewChild('loadButton') load!: ElementRef;

  // TODO: populate the following via the facadeService...
  dataSource: DataSourceType = DataSourceType.LCM;
  assessmentApiService = inject(AssessmentApiService);
  usageService = inject(UsageService);
  userService = inject(UserService);
  wasmService = inject(WasmService);
  configDataService = inject(ConfigDataService);

  private contentDataService = inject(ContentMetadataService);
  private messageService = inject(MessageService);
  private formBuilder = inject(UntypedFormBuilder);

  constructor() {
    this.availableErrors = MESSAGES.map(({code, errorName}) => ({code, errorName}));
  }

  ngOnInit(): void {
    const stashedInfo = localStorage.getItem(this.executeStash);
    let group = {
      startPath: '',
      endPath: '',
      includeEnd: false,
      rememberLast: false,
    };
    if (stashedInfo) {
      group = JSON.parse(stashedInfo);
    }
    this.executePathForm = this.formBuilder.group(group);
    this.errorForm = this.formBuilder.group({ errorCode: '' });
    this.usageForm = new FormGroup({
      usageOverrideSwitch: new FormControl(this.usageService.usageOverride),
      usageCurrent: new FormControl(this.usageService.manualUsage),
      usageGoal: new FormControl(this.usageService.manualUsageGoal)
    });
    this.usageForm.valueChanges
      .pipe(
        debounceTime(500),
      )
      .subscribe(({usageOverrideSwitch, usageCurrent, usageGoal}) => {
        this.usageService.usageOverride = usageOverrideSwitch;
        if(usageCurrent !== this.usageService.manualUsage) {
          this.usageService.manualUsage = usageCurrent;
        }
        if(usageGoal !== this.usageService.manualUsageGoal) {
          this.usageService.manualUsageGoal = usageGoal;
        }
        this.usageService.getWeeklyUsage();
      });
  }

  ngAfterViewInit(): void {
    this.save.nativeElement.disabled = true;
  }

  downloadLog(): void {
    return window._isapp_module?.downloadLog();
  }

  loadIni(): void {
    const logText = window._isapp_module?.readUtf8File(this.iniPath);
    this.contents.nativeElement.value = logText;
    this.save.nativeElement.removeAttribute('disabled');
  }

  saveIni(): void {
    const newContents = this.contents.nativeElement.value;
    if (newContents) {
      window._isapp_module?.writeUtf8File(this.iniPath, newContents);
    }
  }

  executeFormSubmit(): void {
    // console.log("We're trying to execute :> ", this.executePathForm.value);

    if (this.executePathForm.value.rememberLast) {
      localStorage.setItem(
        this.executeStash,
        JSON.stringify(this.executePathForm.value)
      );
    } else {
      localStorage.removeItem(this.executeStash);
    }
    this.wasmService
      .executeActivityRange(
        this.executePathForm.value.startPath,
        this.executePathForm.value.endPath,
        !this.executePathForm.value.includeEnd
      )
  }

  effectiveDateSubmit(): void {
    const dateToSet = new Date(this.effectiveDate);
    const monthToSet = dateToSet.getMonth() + 1;
    const dayToSet = dateToSet.getDate() + 1;
    const yearToSet = dateToSet.getFullYear();

    // console.log("We're moving today to be", dateToSet.getMonth() + 1, dateToSet.getDate() + 1, dateToSet.getFullYear());
    this.wasmService.setEffectiveDate(
      monthToSet,
      dayToSet,
      yearToSet
    );
    this.displayDate = monthToSet + '/' + dayToSet + '/' + yearToSet;
  }

  checkEffectiveDate(): boolean {
    if(!this.effectiveDate){
      return false;
    }
    const date = new Date(this.effectiveDate);
    return date >= this.DATE_MIN && date <= this.DATE_MAX;
  }

  showMessageSubmit(): void {
    this.messageService.showMessage(
      +this.errorForm.get('errorCode')?.value,
      () => this.wasmService.showDebugPanelBehavior.next(true)
    );
    this.closeDebugMenu();
  }

  closeDebugMenu(): void {
    this.wasmService.showDebugPanelBehavior.next(false);
  }

  openJumpSceneButton(): void {
    this.closeDebugMenu();
    this.wasmService.openJumpScene();
  }

  assessmentApiYearToConsole(): void {
    this.assessmentApiService.fetchSchoolYear().subscribe((data) => {
      console.log('School year from Assessment API:', data);
    });
  }

  assessmentApiFormSubmit(button: string): void {
    const year = this.apiYear ? parseInt(this.apiYear, 10) : undefined;
    switch (button) {
      case'raw': this.assessmentApiRawToConsole(year); break;
      case'transformed': this.assessmentApiTransformedToConsole(year); break;
      default: // do nothing
    }
  }

  assessmentApiRawToConsole(year: number | undefined): void {
    this.assessmentApiService.fetchDataRaw(year).subscribe(data => {
      console.log(
        'Raw data from API (by product) for',
        this.schoolYearString(year),
        'school year:',
        data
      );
    });
  }

  assessmentApiTransformedToConsole(year: number | undefined): void {
    this.assessmentApiService.fetchDataTransform(year).subscribe(data => {
      console.log(
        'Transformed Assessment data from API for',
        this.schoolYearString(year),
        'school year:',
        data
      );
    });
  }

  schoolYearString(year: number | undefined): string {
    return year ? year + '' : 'current'
  }

  async dumpUserObject(): Promise<void> {
    const obj = await this.wasmService.generateUserDataObject();
    console.log({obj});
  }

  async dumpLegacyAssignmentStatus(subject: string | undefined = undefined): Promise<void> {
    const status = await this.userService.hasAssignments(subject);
    console.log(`Has legacy assignments (${subject? subject : 'all'}):`, status );
  }

  async dumpAssignments(subject: string | undefined = undefined): Promise<void> {
    const response = await this.contentDataService.getAssignments({subjectFilter: getIstationSubject(subject), route: AssignmentRoute.STUDENT})
    console.log(`dump assignments for ${getIstationSubject(subject)} (from ${subject}):`, response);
  }

  async dumpHasAssignments(subject: string | undefined = undefined): Promise<void> {
    const response = await this.contentDataService.hasIncompleteAssignments(getIstationSubject(subject));
    console.log(`Are there incomplete assignments for ${getIstationSubject(subject)} (from ${subject})?: ${response}`);
  }

  async dumpConfigData(): Promise<void> {
    const configData = await this.configDataService.loadData();
    console.log(JSON.stringify(configData));
  }
}
