import { IsipShortcutParams } from "../enums";

/*
  valid actions:
  WASM activity
  - ['wasm', 'SomeRuntimeRoutingPath', 'OptionalReturnWebPath'*],
  WASM activity range
  - ['wasmr", 'SomeRuntimeStartingPath', 'SomeRuntimeEndingPath', 'true/false', 'OptionalReturnWebPath'*],
  Menu
  - ['menu', 'SomeWebPath'],
  webISIP
  - [ 'webIsip', 'SomeWebPath', 'SomeIsipShortcutParams' ]
  Amira
  - ['amira', 'SomeWebPath']
  Assignments
  - ['assign', 'SomeWebPath']

  * arguments are optional and will default to the current web path if not specified
 */

const activityActions: {[index:string]: string[]} = {
  ReadingIsip: ['wasm', 'ControlRoom.StudentExperience.Isip.Reading_start', 'reading/isip/results/progress'],
  ReadingWebIsip: ['webIsip', 'reading/isip/results/progress', IsipShortcutParams.EnglishReading],
  ReadingLinear: ['wasm', 'ControlRoom.StudentExperience.Linear.Reading_start'],
  ReadingFeatured: ['wasm', 'ControlRoom.StudentExperience.FeaturedActivities.Reading_start'],
  ReadingAssignments: ['menu', 'reading/assignments'],
  ReadingClassicAssignments: ['wasm', 'ControlRoom.StudentExperience.Assignments.Reading_start'],
  ReadingIpractice: ['wasm', 'Ipractice.IpracticeSE.Reading_start'],
  ReadingClassicIpractice: ['wasm', 'Ipractice.Ipractice_Ipractice.default'],
  ReadingLessons: ['menu', 'reading/lesson'],
  ReadingTeacherStation: ['wasm', 'Ipractice.Ipractice_TeacherStation.default'],
  ReadingTeacherLibrary: ['wasm', 'Ipractice.Ipractice_Library.default'],
  ReadingModeling: ['wasm', 'ControlRoom.StudentExperience.Modeling.Reading_start'],
  ReadingGames: ['wasm', 'Ipractice.IpracticeSE.ReadingGames_start'],
  ReadingBooks: ['wasm', 'Ipractice.IpracticeSE.ReadingBooks_start'],
  ReadingWorldOfWonders: ['wasm', 'ControlRoom.StudentExperience.Links.Tt2WorldOfWonders_start'],
  ReadingTt2WorldOfWonders: ['wasm', 'ControlRoom.StudentExperience.Links.Tt2WorldOfWonders_start'],
  ReadingTt2Arcade: ['wasm', 'ControlRoom.StudentExperience.Links.Tt2Arcade_start'],
  ReadingAssignment: ['assign', 'reading/assignments'],
  ReadingTutor: ['amira', 'reading/tutor'],
  MathIsip: ['wasm', 'ControlRoom.StudentExperience.Isip.Math_start', 'math/isip/results/progress'],
  MathWebIsip: ['webIsip', 'math/isip/results/progress', IsipShortcutParams.EnglishMath],
  MathLinear: ['wasm', 'ControlRoom.StudentExperience.Linear.Math_start'],
  MathFeatured: ['wasm', 'ControlRoom.StudentExperience.FeaturedActivities.Math_start'],
  MathAssignments: ['menu', 'math/assignments'],
  MathClassicAssignments: ['wasm', 'ControlRoom.StudentExperience.Assignments.Math_start'],
  MathIpractice: ['wasm', 'Ipractice.IpracticeSE.Math_start'],
  MathClassicIpractice: ['wasm', 'Ipractice.Ipractice_Ipractice.default'],
  MathLessons: ['menu', 'math/lesson'],
  MathTeacherStation: ['wasm', 'Ipractice.Ipractice_TeacherStation.default'],
  MathModeling: ['wasm', 'ControlRoom.StudentExperience.Modeling.Math_start'],
  MathGames: ['wasm', 'Ipractice.IpracticeSE.MathGames_start'],
  MathTools: ['menu', 'math/tools'],
  MathToolsWholeNumberLine: ['wasm', 'Math.Math_Teacher_Tools.Math_TT_NumberLineTool_WholeNumbers'],
  MathToolsDecimalNumberLine: ['wasm', 'Math.Math_Teacher_Tools.Math_TT_NumberLineTool_Decimals'],
  MathToolsFractionBars: ['wasm', 'Math.Math_Teacher_Tools.Math_TT_FractionBarTool'],
  MathToolsPvmK: ['wasm', 'Math.Math_Teacher_Tools.Math_TT_PVM_K'],
  MathToolsPvmM: ['wasm', 'Math.Math_Teacher_Tools.Math_TT_PVM_M'],
  MatemáticasIsip: ['wasm', 'ControlRoom.StudentExperience.Isip.SpanishMath_start', 'matemáticas/isip/results/progress'], 
  MatemáticasWebIsip: ['webIsip', '', IsipShortcutParams.SpanishMath],
  LecturaIsip: ['wasm', 'ControlRoom.StudentExperience.Isip.Spanish_start', 'lectura/isip/results/progress'],
  LecturaWebIsip: ['webIsip', 'lectura/isip/results/progress', IsipShortcutParams.SpanishReading],
  LecturaLinear: ['wasm', 'ControlRoom.StudentExperience.Linear.Spanish_start'],
  LecturaNovedades: ['wasm', 'ControlRoom.StudentExperience.FeaturedActivities.Spanish_start'],
  LecturaAssignments: ['menu', 'lectura/assignments'],
  LecturaClassicAssignments: ['wasm', 'ControlRoom.StudentExperience.Assignments.Spanish_start'],
  LecturaLecciones: ['menu', 'lectura/lesson'],
  LecturaLibros: ['wasm', 'Ipractice.IpracticeSE.SpanishBooks_start'],
  LecturaIpractice: ['wasm', 'Ipractice.IpracticeSE.Spanish_start'],
  LecturaClassicIpractice: ['wasm', 'Ipractice.Ipractice_IpracticeSpanish.default'],
  LecturaTeacherStation: ['wasm', 'Ipractice.Ipractice_TeacherStationSpanish.default'],
  LecturaTeacherLibrary: ['wasm', 'Ipractice.Ipractice_Libros.default'],
  LecturaModeling: ['wasm', 'ControlRoom.StudentExperience.Modeling.Spanish_start'],
  LecturaJuegos: ['wasm', 'Ipractice.IpracticeSE.SpanishGames_start'],
  WritingRules: ['wasm', 'ControlRoom.StudentExperience.WritingRules'],
  IntroToPcEn: ['wasmr', 'Screeners.ScreenerCAT_IntroToPC', 'Screeners.ScreenerCAT_IntroToPC', 'false', '/home'],
  IntroToPcMathEn: ['wasmr', 'ISIPMath.ISIPMath_Early.ISIPMath_IntroToPC', 'ISIPMath.ISIPMath_Early.ISIPMath_IntroToPC', 'false', '/home'],
  IntroToPcEs: ['wasmr', 'ISIP_Espanol.ISIP_Espanol_IntroToPC', 'ISIP_Espanol.ISIP_Espanol_IntroToPC', 'false', '/home'],
  IntroToTabletEs: ['wasmr', 'ISIP_Espanol.ISIP_Espanol_IntroToTS', 'ISIP_Espanol.ISIP_Espanol_IntroToTS', 'false', '/home'],
};
export default activityActions;
