import { GradeBands } from "../enums"

type SubjectDescription = {
    [key in GradeBands]: string;
};

type SubjectDescriptions = {
    [key: string]: SubjectDescription;
};

const createEmptyBands = (): SubjectDescription => ({
    [GradeBands.NONE]: '',
    [GradeBands.BAND_1]: '',
    [GradeBands.BAND_2]: '',
    [GradeBands.BAND_3]: '',
    [GradeBands.BAND_4]: '',
});

const SubjectDescriptions = {
    Reading: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Adventures in reading',
    },
    Math: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Defeat villains with your math skills.',
    },
    Spanish: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Aventuras en la lectura.',
    },
    ReadingIsip: {
        ...createEmptyBands(),
    },
    MathIsip: {
        ...createEmptyBands(),
    },
    SpanishIsip: {
        ...createEmptyBands(),
    },
    Orf: {
        ...createEmptyBands(),
    },
    EspanolOrf: {
        ...createEmptyBands(),
    },
    ISIPAROrf: {
        ...createEmptyBands(),
    },
    ESPAROrf: {
        ...createEmptyBands(),
    },
    RAN: {
        ...createEmptyBands(),
    },
    EspanolRAN: {
        ...createEmptyBands(),
    },
    AmiraAssessment: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Show what you know',
    },
    ReadingAssignment: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Learn with Amira',
    },
    ReadingTutor: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Read a story with Amira',
    },
};

export const NWEASubjectDescriptions = {
    ...SubjectDescriptions,
    AmiraAssessment: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Show what you know',
    },
    ReadingAssignment: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Learn with Maya',
    },
    ReadingTutor: {
        ...createEmptyBands(),
        [GradeBands.BAND_4]: 'Read a story with Maya',
    },
};

export default SubjectDescriptions;