import { inject, Injectable } from "@angular/core";
import Queries from "@swe/data/queries/amira-assignment-api-queries";
import { 
    AmiraAssignmentInput, 
    AmiraAssignmentResponse, 
    AmiraAddStudentInboxAssignmentInput 
} from "../types";
import { Apollo, ApolloBase } from "apollo-angular";
import { 
    filterInvalidManifestActivities, 
    findNextIncompleteAssignment, 
    isAssessmentTypeAssignment 
} from "@swe/shared/utilities/amira-assignment-utils";
import { MessageService } from "@swe/features/message-popup/message.service";
import { MessageCodes } from "@swe/enums";

@Injectable({
    providedIn: 'root',
})
export class AmiraAssignmentService {
    public apollo: ApolloBase;

    private messageService: MessageService = inject(MessageService);
    
    constructor(private apolloProvider: Apollo) {
        this.apollo = apolloProvider.use('amiraAssignment');
    }

    async hasActiveAssignments(input: AmiraAssignmentInput): Promise<boolean> {
        return await this.getNextAssignment(input).then(assignment => !!assignment);
    }

    async getNextAssignment(input: AmiraAssignmentInput): Promise<AmiraAssignmentResponse | undefined | void> {
        return this.apollo.query<{ studentInbox: AmiraAssignmentResponse[] }>({
            query: Queries.STUDENT_INBOX_QUERY,
            variables: input,
            errorPolicy: 'all',
            fetchPolicy: 'network-only'
        }).toPromise().then((result) => {
            if (result.errors) {
                console.error('Error getting student inbox:', result.errors.map(e => e.message));
                throw new Error('Student inbox query failed.');
            }

            if (!result.data?.studentInbox || !result.data.studentInbox.length) {
                return;
            }

            // Filter out invalid manifest activities from non-assessment assignments
            const filteredAssignments = result.data.studentInbox.map(assignment => {
                if (isAssessmentTypeAssignment(assignment.assignmentType)) {
                    // Do not touch assessment manifests, since we don't use individual manifest items for launch
                    return assignment;
                }

                return {
                    ...assignment,
                    manifest: filterInvalidManifestActivities(assignment.manifest)
                }
            });

            return findNextIncompleteAssignment(filteredAssignments);
        }).catch((error) => {
            this.messageService.handleError(MessageCodes.HTTP_NET_GENERIC);
        });
    }

    async addStudentAssignment(input: AmiraAddStudentInboxAssignmentInput): Promise<AmiraAssignmentResponse | undefined | void> {
        return this.apollo.mutate<{ addStudentInboxAssignment?: AmiraAssignmentResponse }>({
            mutation: Queries.ADD_STUDENT_ASSIGNMENT_MUTATION,
            variables: input,
            errorPolicy: 'all'
        }).toPromise().then((result) => {
            if (result.errors) {
                console.error('Error adding student assignment:', result.errors.map(e => e.message));
                throw new Error('Student assignment query failed.');
            }
            
            if (!result.data?.addStudentInboxAssignment) {
                throw new Error('Unable to add student assignment.');
            }

            return result.data?.addStudentInboxAssignment;
        }).catch((error) => {
            this.messageService.handleError(MessageCodes.HTTP_UNEXPECTED_NET_ERROR);
        });
    }
}