import { gql } from "apollo-angular";

export const CREATE_ACTIVITY_MUTATION = gql`
    mutation ($type: String!, $studentId: String!, $storyId: String!, $status: String, $displayStatus: String, $districtId: String, $schoolId: String, $tags: [ActivityTags], $assignmentId: String) {
      putActivity(type: $type, studentId: $studentId, storyId: $storyId, status: $status, displayStatus: $displayStatus, districtId: $districtId, schoolId: $schoolId, tags: $tags, assignmentId: $assignmentId) {
        activityId
      }
    }
`;

export const GET_LICENSING = gql`
    query ($districtId: Int!, $schoolId: Int!) {
      getAmiraLicenses(districtId: $districtId, schoolId: $schoolId) {
        branding
      }
    }
`;

export default { 
  CREATE_ACTIVITY_MUTATION,
  GET_LICENSING
};