import {
    AmiraAssignmentInput, 
    AmiraAssignmentResponse 
} from "@swe/types"
import { 
    AmiraAssignmentEntityTypes, 
    AmiraAssignmentActivityTypes, 
    AmiraAssignmentStatus 
} from "@swe/enums"

// schema definition: https://github.dev/AmiraLearning/amira-assignment-service/blob/develop/res/schema.graphql

export const AmiraAssignmentInputSample: AmiraAssignmentInput = {
    studentId: '1FC56C1931EC4D1B8B391F5A51EC386B',
    schoolId: '1040144',
    districtId: '1040132'
};

export const AmiraAllAssignmentSample: AmiraAssignmentResponse[] = [{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "SECOND",
    "status": "DONE",
    "manifest": [{
        "activity": [{
            "activityId": "6831857E750211EFBC750E38A4228D93",
            "createdAt": "2024-09-17T14:37:47.000Z",
            "displayStatus": "completed",
            "districtId": "1040132",
            "schoolId": "1040144",
            "updatedAt": "2024-09-20T02:43:25.000Z",
            "type": "microLesson",
            "studentId": "1FC56C1931EC4D1B8B391F5A51EC386B",
            "storyId": "E62F0FD019AB11EE81FBCF2B4B2A7ED5",
            "status": AmiraAssignmentStatus.SCORED,
            "score_pass": 0,
            "assignmentId": null,
            "parentId": "CC94A350750211EFBC750E38A4228D93"
        }],
        "activityType": AmiraAssignmentActivityTypes.SKILL,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "DONE",
        "storyId": null,
        "launchArgs" : {
            "storyId": "",          // if story id is null, we assume this is a skill-based assignment
            "skill": "amiraSkillSkill"
        }
    },
    {
        "activity": [{
            "activityId": "CC94A350750211EFBC750E38A4228D93",
            "createdAt": "2024-09-17T14:40:35.000Z",
            "displayStatus": "completed",
            "districtId": "1040132",
            "schoolId": "1040144",
            "updatedAt": "2024-09-18T02:12:24.000Z",
            "type": "microLesson",
            "studentId": "1FC56C1931EC4D1B8B391F5A51EC386B",
            "storyId": "BA0B4870EFDB11EB991FB9A1B708BAD5",
            "status": AmiraAssignmentStatus.SCORED,
            "score_pass": 1,
            "assignmentId": null,
            "parentId": "",
        }],
        "activityType": AmiraAssignmentActivityTypes.MICROLESSON,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "DONE",
        "storyId": null,
        "launchArgs" : {
            "storyId": "",          // if story id is null, we assume this is a skill-based assignment
            "skill": "amiraSkillSkill"
        }
    },
    {
        "activity": [{
            "activityId": "7630B287750311EFBC750E38A4228D93",
            "createdAt": "2024-09-17T14:45:20.000Z",
            "displayStatus": "completed",
            "districtId": "1040132",
            "schoolId": "1040144",
            "updatedAt": "2024-09-18T02:12:23.000Z",
            "type": "istationLegacyActivity",
            "studentId": "1FC56C1931EC4D1B8B391F5A51EC386B",
            "storyId": "C0C65650E9CB11EDBC5229ED5903B97C",
            "status": AmiraAssignmentStatus.SCORED,
            "score_pass": 0,
            "assignmentId": null,
            "parentId": "CC94A350750211EFBC750E38A4228D93"
        }],
        "activityType": AmiraAssignmentActivityTypes.LEGACYISTATION,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "BREAK",
        "status": "DONE",
        "storyId": null,
        "launchArgs" : {
            "storyId": "",          // if story id is null, we assume this is a skill-based assignment
            "skill": "amiraSkillSkill"
        }
    }
]},
{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1z",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "SECOND",
    "status": "DONE",
    "manifest": [{
        "activity": [{
            "activityId": "6831857E750211EFBC750E38A4228D85",
            "createdAt": "2024-09-17T14:37:47.000Z",
            "displayStatus": "completed",
            "districtId": "1040132",
            "schoolId": "1040144",
            "updatedAt": "2024-09-20T02:43:25.000Z",
            "type": "microLesson",
            "studentId": "1FC56C1931EC4D1B8B391F5A51EC386B",
            "storyId": "E62F0FD019AB11EE81FBCF2B4B2A7EZ8",
            "status": AmiraAssignmentStatus.SCORED,
            "score_pass": 0,
            "assignmentId": null,
            "parentId": "null"
        }],
        "activityType": AmiraAssignmentActivityTypes.MICROLESSON,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "DONE",
        "storyId": null,
        "launchArgs" : {
            "storyId": "",          // if story id is null, we assume this is a skill-based assignment
            "skill": "amiraSkillSkill"
        }
    }]
}];

export const AmiraActiveAssignmentSample: AmiraAssignmentResponse[] = [{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "ASSESSMENT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "THIRD",
    "status": "ASSIGNED",
    "manifest": [{
        "activity": [{
            "activityId": "6831857E750211EFBC750E38A4228D93",
            "createdAt": "2024-09-17T14:37:47.000Z",
            "displayStatus": "completed",
            "districtId": "1040132",
            "schoolId": "1040144",
            "updatedAt": "2024-09-20T02:43:25.000Z",
            "type": "microLesson",
            "studentId": "1FC56C1931EC4D1B8B391F5A51EC386B",
            "storyId": "E62F0FD019AB11EE81FBCF2B4B2A7ED5",
            "status": AmiraAssignmentStatus.SCORED,
            "score_pass": 0,
            "assignmentId": null,
            "parentId": "CC94A350750211EFBC750E38A4228D93"
        }],
        "activityType": AmiraAssignmentActivityTypes.ASSESSMENT,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "storyId": "amiraAssessStoryId",
            "skill": "amiraAssessSkill"
        }
    }],
},
{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "THIRD",
    "status": "ASSIGNED",
    "manifest": [{
        "activityType": AmiraAssignmentActivityTypes.MICROLESSON,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "activityUuid": "018f064e-a8dc-7604-b6a6-1e483794df36",
            "activityOid": "26659",
            "skill": "Analyzing Poetry"
        }
    },
    {
        "activityType": AmiraAssignmentActivityTypes.STORY,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "storyId": "amiraStoryId",   // if story id is populated, we assume this is an amira story
            "skill": "amiraStorySkill"
        }
    },
    {
        "activityType": AmiraAssignmentActivityTypes.SKILL,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "storyId": "",          // if story id is null, we assume this is a skill-based assignment
            "skill": "amiraSkillSkill"
        }
    },
    {
        "activityType": AmiraAssignmentActivityTypes.LEGACYISTATION,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "BREAK",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "startingJumpPath": "Screeners.ScreenerCAT_IntroToPC",
            "endingJumpPath": "Screeners.ScreenerCAT_IntroToPC",
            "stopBefore": "false",
            "skill": "Intro To PC"
        }
    }]
}];

export const AmiraLegacyAssignmentSample: AmiraAssignmentResponse[] = [{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "THIRD",
    "status": "ASSIGNED",
    "manifest": [{
        "activityType": AmiraAssignmentActivityTypes.LEGACYISTATION,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "BREAK",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "startingJumpPath": "Screeners.ScreenerCAT_IntroToPC",
            "endingJumpPath": "Screeners.ScreenerCAT_IntroToPC",
            "stopBefore": "false",
            "skill": "Intro to PC"
        }
    }]
}];

export const AmiraMicroLessonAssignmentSample: AmiraAssignmentResponse[] = [{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "THIRD",
    "status": "ASSIGNED",
    "manifest": [{
        "activityType": AmiraAssignmentActivityTypes.MICROLESSON,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "activityUuid": "018f064e-a8dc-7604-b6a6-1e483794df36",
            "activityOid": "26659",
            "skill": "Analyzing Poetry"
        }
    }]
}];

export const AmiraStoryAssignmentSample: AmiraAssignmentResponse[] = [{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "THIRD",
    "status": "ASSIGNED",
    "manifest": [{
        "activityType": AmiraAssignmentActivityTypes.STORY,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "storyId": "e1022d50-d677-11ea-8a97-fdfeebb439f1",   // if story id is populated, we assume this is an amira story
        }
    }]
}];

export const AmiraSkillAssignmentSample: AmiraAssignmentResponse[] = [{
    "assignmentId": "ad4b3cbe-6994-43af-8253-e2b3f423ad1a",
    "assignmentType": "INSTRUCT",
    "assignor": null,
    "createdAt": "2024-09-17 14:36:49",
    "dateFrom": null,
    "dateTo": null,
    "entityId": "1fc56c19-31ec-4d1b-8b39-1f5a51ec386b",
    "entityType": AmiraAssignmentEntityTypes.STUDENT,
    "grade": "THIRD",
    "status": "ASSIGNED",
    "manifest": [{
        "activityType": AmiraAssignmentActivityTypes.SKILL,
        "contentTags": [],
        "locale": "EN_US",
        "sequence": "CONTINUE",
        "status": "ASSIGNED",
        "storyId": null,
        "launchArgs" : {
            "skill": "amiraSkillSkill"
        }
    }]
}];

export const AmiraEmptyAssignmentSample: AmiraAssignmentResponse[] = [];

export default {
    AmiraAllAssignmentSample,
    AmiraActiveAssignmentSample,
    AmiraLegacyAssignmentSample,
    AmiraMicroLessonAssignmentSample,
    AmiraStoryAssignmentSample,
    AmiraSkillAssignmentSample,
    AmiraEmptyAssignmentSample
}